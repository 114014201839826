import React, { useEffect, useRef, useState } from 'react'
import "./projectlist.css"
import { projectData } from '../../helpers/projectdata'
import { Link } from 'react-router-dom';

function ProjectList() {

    const options = ["All Works", "Brand Identity", "Packaging", "Web Development"]

    const [isActive, setIsActive] = useState(false);
    const [selected, setSelected] = useState('All Works');

    let menuRef = useRef();

    useEffect(() => {
        let handler = (e)=>{
            if(!menuRef.current.contains(e.target)){
                setIsActive(false);
            }      
        };

        document.addEventListener("mousedown", handler);

        return() =>{
            document.removeEventListener("mousedown", handler);
        }
    });

    const [data, setData] = useState([]);
    const countProject = projectData.length;

    useEffect(() => {
        setData(projectData);
    }, []);

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
            <div className='page-section'>
                <div className='project-container '>
                    <div className='grid-container pt-[24vw] sm:pt-[1.5vw]'>
                        <div className='project-heading-container'>
                            <h1 className='project-heading'>Work</h1>
                            <p className='project-heading-subscript'>{countProject}</p>
                        </div>
                        <div className='project-filter-container'>
                            <p className='project-filter mr-0 sm:mr-[1vw]'>Filter :</p>
                            <div className='dropdown' ref={menuRef}>
                                <div className='dropdown-btn filter-underline' onClick={(e) => setIsActive(!isActive)}>
                                    {selected}
                                </div>
                                <div className={`dropdown-content ${isActive ? 'active' : 'inactive'}`}>
                                    {options.map((option) => 
                                        <div className='dropdown-item' onClick={(e) => {
                                            setSelected(option);
                                            setIsActive(false);
                                        }}>{option}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='line pt-[3vw] sm:pt-[1vw] lg:pt-[1vw]'>
                        <div className='black-line'></div>
                    </div>
                </div>
                <div>
                    {
                        data.map((item) =>
                            <div className='' >
                                <div className='padding-x'>
                                    <div className={`grid-container lg:py-[1.5vw]`}>
                                        <div className='col-start-1 col-end-5'>
                                            <p className='project-list-haas'>{item.title}</p>
                                        </div>
                                        <div className='col-start-5 col-end-12'>
                                            <p className='project-list-haas'>
                                            {item.service.map((service, index) => (
                                                <span key={index}>
                                                    {service}
                                                    {index < item.service.length - 1 ? " — " : ""}
                                                </span>
                                            ))}
                                            </p>
                                        </div>
                                        <div className='col-start-12 col-end-13 flex justify-end'>
                                            <span className='project-list-haas'>(+)</span>
                                        </div>
                                    </div>
                                    <div className='line'>
                                        <div className='black-line'></div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
        )
}

export default ProjectList