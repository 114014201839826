import React from 'react'
import NavBar from '../../components/NavBar'
import Project from '../../components/Project'
import RadioBtn from '../../components/RadioButton'


function ProjectPage() {
  return (
    <>
      {/* <NavBar /> */}
      {/* <RadioBtn defaultChecked={"grid"}/> */}
      <Project />
    </>
  )
}

export default ProjectPage