import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Arrow from '../../public/asset/Icons/ARROW_BLANK_STUDIO.svg'

import Img1 from '../../public/asset/images/works/sukakamu/SUKAKAMU_2.jpg'
import Img2 from '../../public/asset/images/works/sukakamu/SUKAKAMU_4.jpg'
import Img3 from '../../public/asset/images/works/sukakamu/SUKAKAMU_6.jpg'
import Img4 from '../../public/asset/images/works/sukakamu/SUKAKAMU_5.jpg'
import Img5 from '../../public/asset/images/works/sukakamu/SUKAKAMU_8.jpg'

function SukaKamu() {

    const information = useRef(null);

    const scrollToSection = (ref) => {
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        
        class LoopingElement {
            constructor(element, currentTranslation, speed) {
                this.element = element;
                this.currentTranslation = currentTranslation;
                this.speed = speed;
                this.direction = false;
                this.scrollTop = 0;
                this.metric = 100;
        
                this.lerp = {
                    current: this.currentTranslation,
                    target: this.currentTranslation,
                    factor: 0.2,
                };
        
                this.events();
                this.render();
            }
        
            events() {
                window.addEventListener("scroll", (e) => {
                    let direction =
                        window.pageYOffset || document.documentElement.scrollTop;
                    if (direction > this.scrollTop) {
                        // this.direction = true;
                        this.lerp.target -= this.speed * 5;
                    } else {
                        // this.direction = false;
                        this.lerp.target -= this.speed * 5;
                    }
                    this.scrollTop = direction <= 0 ? 0 : direction;
                });
            }
        
            lerpFunc(current, target, factor) {
                this.lerp.current = current * (1 - factor) + target * factor;
            }
        
            goForward() {
                this.lerp.target += this.speed;
                if (this.lerp.target > this.metric) {
                    this.lerp.current -= this.metric * 2;
                    this.lerp.target -= this.metric * 2;
                }
            }
        
            goBackward() {
                this.lerp.target -= this.speed;
                if (this.lerp.target < -this.metric) {
                    this.lerp.current -= -this.metric * 2;
                    this.lerp.target -= -this.metric * 2;
                }
            }
        
            animate() {
                this.direction ? this.goForward() : this.goBackward();
                this.lerpFunc(this.lerp.current, this.lerp.target, this.lerp.factor);
        
                this.element.style.transform = `translateX(${this.lerp.current}%)`;
            }
        
            render() {
                this.animate();
                window.requestAnimationFrame(() => this.render());
            }
        }
        
        let elements = document.querySelectorAll(".track");
        
        new LoopingElement(elements[0], 0, 0.1);
        new LoopingElement(elements[1], -100, 0.1);

    }, []);

    return (
    <div>
        <div className='page-section'>
            <div className='marquee'>
                <div className='track'>
                    <span className='client-title'>Suka Kamu</span>
                    <span className='work-circle'></span>
                    <span className='client-title'>Suka Kamu</span>
                    <span className='work-circle'></span>
                </div>
                <div className='track'>
                    <span className='client-title'>Suka Kamu</span>
                    <span className='work-circle'></span>
                    <span className='client-title'>Suka Kamu</span>
                    <span className='work-circle'></span>
                </div>
            </div>

            <div className='padding-x pb-[5vw] sm:pb-[1.5vw]'>
                <div className='sticky'>
                    <div className='line'>
                        <div className='black-line'></div>
                    </div>
                    <div className='client-bar' ref={information}>
                        <div className='grid-container'>
                            <div className='col-start-1 col-end-3 sm:col-end-5 lg:col-end-7 flex'>
                                <p className='section-haas'>Cinta Laura</p>
                                <p className='section-haas hidden sm:block sm:px-[0.5vw]'>&mdash;</p>
                                <p className='section-haas hidden sm:block'>Suka Kamu</p>
                            </div>
                            <div className='hidden lg:flex sm:col-start-5 sm:col-end-7 lg:col-start-7 lg:col-end-10'>
                                <p className='anchor-haas' onClick={() => scrollToSection(information)}>Information</p>
                            </div>
                            <div className='col-start-3 col-end-5 sm:col-start-7 sm:col-end-9 lg:col-start-10 lg:col-end-13 flex justify-end'>
                                <Link to='/work' className='flex'>
                                    <img src={Arrow} alt='arrow' className='arrow-right'/>
                                    <p className='section-haas'>Next Work</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='grid-container'>
                    <div className='col-start-1 col-end-5 sm:col-end-9 lg:col-end-7'>
                        <video autoPlay muted playsInline loop className='w-full'>
                            <source src={require('../../public/asset/images/selectedworks/SW_SUKAKAMU.mp4')} type="video/mp4"/>
                        </video>
                    </div>
                    <div className='pb-[10vw] pt-[1vw] sm:pb-0 sm:pt-[1.5vw] lg:pt-0 lg:pb-0 col-start-1 col-end-5 sm:col-end-9 lg:col-start-7 lg:col-end-13'>
                        <div className='hidden lg:block lg:line'>
                            <div className='black-line'></div>
                        </div>
                        <div className='client-detail-bar'>
                            <p className='client-detail-haas'>
                                For this project Acaii Tea worked with Blank Studio to create a stunning packaging
                                and label design for Acaii Tea's bird's nest product line. Using elements resembling
                                the swallow bird while moving out from general trend of a bird's nest product to 
                                create a unique and distinctive aesthetic. 
                            </p>
                        </div>
                        <div className='line'>
                            <div className='black-line'></div>
                        </div>
                        <div className='client-detail-bar'>
                            <div className='half-grid'>
                                <div className='col-start-1 col-end-2 lg:col-end-3'>
                                    <p className='client-detail-haas'>Client</p>
                                </div>
                                <div className='col-start-2 col-end-5 sm:col-start-3 sm:col-end-5 lg:col-start-3 lg:col-end-7'>
                                    <p className='client-detail-haas'>Cinta Laura</p>
                                </div>
                            </div>
                        </div>
                        <div className='line'>
                            <div className='black-line'></div>
                        </div>
                        <div className='client-detail-bar'>
                            <div className='half-grid'>
                                <div className='col-start-1 col-end-2 lg:col-end-3'>
                                    <p className='client-detail-haas'>Type</p>
                                </div>
                                <div className='col-start-2 col-end-5 sm:col-start-3 sm:col-end-5 lg:col-start-3 lg:col-end-7'>
                                    <p className='client-detail-haas'>Music Video</p>
                                </div>
                            </div>
                        </div>
                        <div className='line'>
                            <div className='black-line'></div>
                        </div>
                        <div className='client-detail-bar'>
                            <div className='half-grid'>
                                <div className='col-start-1 col-end-2 lg:col-end-3'>
                                    <p className='client-detail-haas'>Services</p>
                                </div>
                                <div className='col-start-2 col-end-5 sm:col-start-3 sm:col-end-5 lg:col-start-3 lg:col-end-7'>
                                    <p className='client-detail-haas'>Typography, Poster Design</p>
                                </div>
                            </div>
                        </div>
                        <div className='hidden lg:block lg:line'>
                            <div className='black-line'></div>
                        </div>
                    </div>
                </div>
            </div>

            <img src={Img1} alt='' className='sm:pt-[9vw]' />
            <div className='padding-x'>
                <div className='grid-container py-[15vw] sm:py-[10vw] lg:py-[10vw]'>
                    <div className='col-start-1 col-end-5 sm:col-start-1 sm:col-end-4 lg:col-start-1 lg:col-end-6'>
                        <video autoPlay muted playsInline loop>
                            <source src={require('../../public/asset/images/works/sukakamu/SUKAKAMU_3_1.mp4')} type="video/mp4"/>
                        </video>
                    </div>
                    <div className='pt-[5vw] sm:pt-0 col-start-1 col-end-5 sm:col-start-5 sm:col-end-9 lg:col-start-7 lg:col-end-13'>
                        <img src={Img2} alt='' />
                    </div>
                </div>
            </div>
            <img src={Img3} alt='' />
            <div className='main-text-container'>
                <div className='flex'>
                    <span className='block sm:hidden main-text-haas'>A song that gracefully</span>

                    <span className='hidden sm:block main-text-haas'>A song that gracefully evokes a</span>
                </div>
                <div className='flex'>
                    <span className='block sm:hidden main-text-haas'>evokes a</span>
                    <span className='block sm:hidden main-text-pp tracking-[-0.4vw] pl-[2vw] sm:pl-[1vw]'>blossoming</span>

                    <span className='hidden sm:block main-text-pp tracking-[-0.4vw] pr-[1vw]'>blossoming sensation</span>
                    <span className='hidden sm:block main-text-haas'>in people's hearts</span>
                </div>
                <div className='flex'>
                    <span className='block sm:hidden main-text-pp tracking-[-0.4vw] pr-[2vw] sm:pr-[1vw]'>sensation</span>
                    <span className='block sm:hidden main-text-haas'>in people's</span>

                    <span className='hidden sm:block main-text-haas'>when they are in love, yet instills a sense</span>
                </div>
                <div className='flex'>
                    <span className='block sm:hidden main-text-haas'>hearts when they are</span>

                    <span className='hidden sm:block main-text-haas'> of</span>
                    <span className='hidden sm:block main-text-pp tracking-[-0.4vw] px-[1vw]'>confidence</span>
                    <span className='hidden sm:block main-text-haas'>within them.</span>
                </div>
                <div className='flex sm:hidden'>
                    <span className='main-text-haas'>in love, yet instills a</span>
                </div>
                <div className='flex sm:hidden'>
                    <span className='main-text-haas'>sense of</span>
                    <span className='main-text-pp tracking-[-0.4vw] pl-[2vw] sm:pl-[1vw]'>confidence</span>
                </div>
                <div className='flex sm:hidden'>
                    <span className='main-text-haas'>within them.</span>
                </div>
            </div>
            <div className='padding-x'>
                <div className='grid-container pb-[15vw] sm:pb-[10vw] lg:pb-[10vw]'>
                    <div className='col-start-1 col-end-5 sm:col-end-5 lg:col-end-7'>
                        <img src={Img4} alt=''/>
                    </div>
                    <div className='pt-[5vw] sm:pt-0 col-start-1 col-end-5 sm:col-start-6 sm:col-end-9 lg:col-start-8 lg:col-end-13'>
                        <video autoPlay muted playsInline loop>
                            <source src={require('../../public/asset/images/works/sukakamu/SUKAKAMU_7.mp4')} type="video/mp4"/>
                        </video>
                    </div>
                </div>
            </div> 
            <img src={Img5} alt=''/>
            <div className='padding-x'>
                <div className='grid-container py-[15vw] sm:py-[10vw] lg:py-[10vw]'>
                    <div className='col-start-1 col-end-5 sm:col-end-9 lg:col-start-2 lg:col-end-12'>
                        <video autoPlay muted playsInline loop className='w-full'>
                            <source src={require('../../public/asset/images/works/sukakamu/SUKAKAMU_9.mp4')} type="video/mp4"/>
                        </video>
                    </div>
                </div>
            </div>  
        </div>
    </div>
    )
}

export default SukaKamu