import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Arrow from '../../public/asset/Icons/ARROW_BLANK_STUDIO.svg'

import Img1 from '../../public/asset/images/works/yukishimizu/YUKI_TWO.jpg'
import Img2 from '../../public/asset/images/works/yukishimizu/YUKI_FOUR.jpg'
import Img3 from '../../public/asset/images/works/yukishimizu/YUKI_FIVE.jpg'
import Img4 from '../../public/asset/images/works/yukishimizu/YUKI_THREE.jpg'
import Img5 from '../../public/asset/images/works/yukishimizu/YUKI_ONE.jpg'
import Img6 from '../../public/asset/images/works/yukishimizu/YUKI_SEVEN.jpg'
import Img7 from '../../public/asset/images/works/yukishimizu/YUKI_EIGHT.jpg'
import Img8 from '../../public/asset/images/works/yukishimizu/YUKI_NINE.jpg'
import Img9 from '../../public/asset/images/works/yukishimizu/YUKI_TEN.jpg'
import Img10 from '../../public/asset/images/works/yukishimizu/YUKI_ELEVEN.jpg'

function Yukishimizu() {

  const information = useRef(null);

  const scrollToSection = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    
    class LoopingElement {
      constructor(element, currentTranslation, speed) {
        this.element = element;
        this.currentTranslation = currentTranslation;
        this.speed = speed;
        this.direction = false;
        this.scrollTop = 0;
        this.metric = 100;

        this.lerp = {
            current: this.currentTranslation,
            target: this.currentTranslation,
            factor: 0.2,
        };

        this.events();
        this.render();
      }

      events() {
        window.addEventListener("scroll", (e) => {
            let direction =
                window.pageYOffset || document.documentElement.scrollTop;
            if (direction > this.scrollTop) {
                // this.direction = true;
                this.lerp.target -= this.speed * 5;
            } else {
                // this.direction = false;
                this.lerp.target -= this.speed * 5;
            }
            this.scrollTop = direction <= 0 ? 0 : direction;
        });
      }

      lerpFunc(current, target, factor) {
          this.lerp.current = current * (1 - factor) + target * factor;
      }

      goForward() {
          this.lerp.target += this.speed;
          if (this.lerp.target > this.metric) {
              this.lerp.current -= this.metric * 2;
              this.lerp.target -= this.metric * 2;
          }
      }

      goBackward() {
          this.lerp.target -= this.speed;
          if (this.lerp.target < -this.metric) {
              this.lerp.current -= -this.metric * 2;
              this.lerp.target -= -this.metric * 2;
          }
      }

      animate() {
          this.direction ? this.goForward() : this.goBackward();
          this.lerpFunc(this.lerp.current, this.lerp.target, this.lerp.factor);

          this.element.style.transform = `translateX(${this.lerp.current}%)`;
      }

      render() {
          this.animate();
          window.requestAnimationFrame(() => this.render());
      }
    }

  let elements = document.querySelectorAll(".track");

  new LoopingElement(elements[0], 0, 0.1);
  new LoopingElement(elements[1], -100, 0.1);

  }, []);

  return (
  <div>
    <div className='page-section'>
      <div className='marquee'>
          <div className='track'>
              <span className='client-title'>Yukishimizu</span>
              <span className='work-circle'></span>
              <span className='client-title'>Gin</span>
              <span className='work-circle'></span>
          </div>
          <div className='track'>
              <span className='client-title'>Yukishimizu</span>
              <span className='work-circle'></span>
              <span className='client-title'>Gin</span>
              <span className='work-circle'></span>
          </div>
      </div>

      <div className='padding-x pb-[5vw] sm:pb-[1.5vw]'>
        <div className='sticky'>
            <div className='line'>
                <div className='black-line'></div>
            </div>
            <div className='client-bar' ref={information}>
                <div className='grid-container'>
                    <div className='col-start-1 col-end-3 sm:col-end-5 lg:col-end-7 flex'>
                        <p className='section-haas'>Yukishimizu Gin</p>
                    </div>
                    <div className='hidden lg:flex sm:col-start-5 sm:col-end-7 lg:col-start-7 lg:col-end-10'>
                        <p className='anchor-haas' onClick={() => scrollToSection(information)}>Information</p>
                    </div>
                    <div className='col-start-3 col-end-5 sm:col-start-7 sm:col-end-9 lg:col-start-10 lg:col-end-13 flex justify-end'>
                        <Link to='/work' className='flex'>
                            <img src={Arrow} alt='arrow' className='arrow-right'/>
                            <p className='section-haas'>Next Work</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
          <div className='grid-container'>
              <div className='col-start-1 col-end-5 sm:col-end-9 lg:col-end-7'>
                  <img src={Img1} alt=''/>
              </div>
              <div className='pb-[10vw] pt-[1vw] sm:pb-0 sm:pt-[1.5vw] lg:pt-0 lg:pb-0 col-start-1 col-end-5 sm:col-end-9 lg:col-start-7 lg:col-end-13'>
                  <div className='hidden lg:block lg:line'>
                      <div className='black-line'></div>
                  </div>
                  <div className='client-detail-bar'>
                      <p className='client-detail-haas'>
                        The Yukishimizu Gin is designed to reflect the aesthetics of winter in Japan.
                        Yukishimizu which means 'snow water', is translated through pure, clean and 
                        modern design. 
                      </p>
                  </div>
                  <div className='line'>
                      <div className='black-line'></div>
                  </div>
                  <div className='client-detail-bar'>
                      <div className='half-grid'>
                          <div className='col-start-1 col-end-2 lg:col-end-3'>
                              <p className='client-detail-haas'>Client</p>
                          </div>
                          <div className='col-start-2 col-end-5 sm:col-start-3 sm:col-end-5 lg:col-start-3 lg:col-end-7'>
                              <p className='client-detail-haas'>Yukishimizu Gin</p>
                          </div>
                      </div>
                  </div>
                  <div className='line'>
                      <div className='black-line'></div>
                  </div>
                  <div className='client-detail-bar'>
                      <div className='half-grid'>
                          <div className='col-start-1 col-end-2 lg:col-end-3'>
                              <p className='client-detail-haas'>Type</p>
                          </div>
                          <div className='col-start-2 col-end-5 sm:col-start-3 sm:col-end-5 lg:col-start-3 lg:col-end-7'>
                              <p className='client-detail-haas'>New Brand</p>
                          </div>
                      </div>
                  </div>
                  <div className='line'>
                      <div className='black-line'></div>
                  </div>
                  <div className='client-detail-bar'>
                      <div className='half-grid'>
                          <div className='col-start-1 col-end-2 lg:col-end-3'>
                              <p className='client-detail-haas'>Services</p>
                          </div>
                          <div className='col-start-2 col-end-5 sm:col-start-3 sm:col-end-5 lg:col-start-3 lg:col-end-7'>
                              <p className='client-detail-haas'>Packaging Design</p>
                          </div>
                      </div>
                  </div>
                  <div className='hidden lg:block lg:line'>
                      <div className='black-line'></div>
                  </div>
              </div>
          </div>
      </div>

      <img src={Img2} alt='' className='sm:pt-[9vw]' />
      <div className='padding-x'>
          <div className='grid-container py-[15vw] sm:py-[10vw] lg:py-[10vw]'>
              <div className='col-start-1 col-end-5 sm:col-start-1 sm:col-end-4 lg:col-start-1 lg:col-end-6'>
                  <img src={Img3} alt='' />
              </div>
              <div className='pt-[5vw] sm:pt-0 col-start-1 col-end-5 sm:col-start-5 sm:col-end-9 lg:col-start-7 lg:col-end-13'>
                  <img src={Img4} alt='' />
              </div>
          </div>
      </div>
      <img src={Img5} alt='' />
      <div className='main-text-container'>
          <div className='flex'>
              <span className='block sm:hidden main-text-haas'>Yukishimizu Gin</span>

              <span className='hidden sm:block main-text-haas'>Yukishimizu Gin encapsulates the</span>
          </div>
          <div className='flex'>
              <span className='block sm:hidden main-text-haas'>encapsulates the</span>

              <span className='hidden sm:block main-text-haas'>serene</span>
              <span className='hidden sm:block main-text-pp tracking-[-0.4vw] px-[1vw]'>beauty</span>
              <span className='hidden sm:block main-text-haas'>of Japan's</span>
              <span className='hidden sm:block main-text-pp tracking-[-0.4vw] pl-[1vw]'>winter season,</span>
          </div>
          <div className='flex'>
              {/* <span className='block sm:hidden main-text-haas'>serene</span> */}
              <span className='block sm:hidden main-text-pp tracking-[-0.4vw] pr-[2vw] sm:pr-[1vw]'>beauty</span>
              <span className='block sm:hidden main-text-haas'>of Japan's</span>

              <span className='hidden sm:block main-text-haas'>drawing inspiration from the essence</span>
          </div>
          <div className='flex'>
              <span className='block sm:hidden main-text-pp tracking-[-0.4vw]'>winter season</span>

              <span className='hidden sm:block main-text-haas'>of</span>
              <span className='hidden sm:block main-text-pp tracking-[-0.4vw] pl-[1vw]'>"snow water".</span>
          </div>
          <div className='flex sm:hidden'>
              <span className='block sm:hidden main-text-haas'>drawing inspiration</span>
          </div>
          <div className='flex sm:hidden'>
              <span className='main-text-haas'>from the essence of</span>
          </div>
          <div className='flex sm:hidden'>
              <span className='main-text-pp tracking-[-0.4vw]'>"snow water".</span>
          </div>
      </div>
      <img src={Img6} alt='' />
      <div className='padding-x'>
          <div className='grid-container py-[15vw] sm:py-[10vw] lg:py-[10vw]'>
              <div className='col-start-1 col-end-5 sm:col-start-1 sm:col-end-9 lg:col-start-2 lg:col-end-12'>
                  <img src={Img7} alt=''/>
              </div>
          </div>
          <div className='grid-container pb-[15vw] sm:pb-[10vw] lg:pb-[10vw]'>
              <div className='col-start-1 col-end-5 sm:col-start-1 sm:col-end-9 lg:col-start-2 lg:col-end-12'>
                  <img src={Img8} alt=''/>
              </div>
          </div>
      </div>
      <img src={Img9} alt=''/>
      <div className='padding-x'>
          <div className='grid-container py-[15vw] sm:py-[10vw] lg:py-[10vw]'>
              <div className='col-start-1 col-end-5 sm:col-start-1 sm:col-end-9 lg:col-start-2 lg:col-end-12'>
                  <img src={Img10} alt=''/>
              </div>
          </div>
      </div>            
    </div>
  </div>
  )
}

export default Yukishimizu