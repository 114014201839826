import React, { createRef } from 'react'
import { Link } from "react-router-dom"
import "./comingsoon.css"
import LogoVertical from '../../public/asset/logo/LOGO_BLANK STUDIO_VERTICAL.svg'


function Soon() {

  // const ref = useRef(null);

  let steps = 0;
  let currentIndex = 0;
  let nbOfImages = 0;
  let maxNumberOfImages = 8;
  let refs = [];
  let previousTouch = null;
  let touchMoveX = 0;
  let touchMoveY = 0;

  const manageMouseMove = (e) => {
    const { clientX, clientY, movementX, movementY } = e;

    steps+= Math.abs(movementX) + Math.abs(movementY);

    // console.log(steps);


    if(steps >= currentIndex * 100){
      moveImage(clientX, clientY);

      if(nbOfImages === maxNumberOfImages){
        removeImage();
      }
    }
    
    if(currentIndex === refs.length){
      currentIndex = 0;
      steps = -100;
    }
  }

  const manageTouchMove = (e) => {

    const touch = e.changedTouches[0];

    if (previousTouch) {
      // be aware that these only store the movement of the first touch in the touches array
      touchMoveX = touch.pageX - previousTouch.pageX;
      touchMoveY = touch.pageY - previousTouch.pageY;
    };

    previousTouch = touch;    

    steps+= Math.abs(touchMoveX) + Math.abs(touchMoveY);



    if(steps >= currentIndex * 100){
      moveImage(e.changedTouches[0].clientX, e.changedTouches[0].clientY);

      if(nbOfImages === maxNumberOfImages){
        removeImage();
      }
    }
    
    if(currentIndex === refs.length){
      currentIndex = 0;
      steps = -100;
    }
  }

  const moveImage = (x, y) => {
    const currentImage = refs[currentIndex].current;
    currentImage.style.left = x + "px";
    currentImage.style.top = y + "px";
    currentImage.style.display = "block";
    currentIndex++;
    nbOfImages++;
    setZIndex()
  }

  const setZIndex = () => {
    const images = getCurrentImages();
    for(let i = 0 ; i < images.length ; i++){
      images[i].style.zIndex = i;
    }
  }

  const removeImage = () => {
    const images = getCurrentImages();
    images[0].style.display = "none";
    nbOfImages--;
  }

  const getCurrentImages = () => {
    let images = []
    let indexOfFirst = currentIndex - nbOfImages;
    for(let i = indexOfFirst ; i < currentIndex ; i++){
      let targetIndex = i;
      if(targetIndex < 0) targetIndex += refs.length
      images.push(refs[targetIndex].current);
    }
    return images;
  }

  return (
    <div className='soon-section'>

        <div onMouseMove={(e) => {manageMouseMove(e)}} onTouchMove={(e) => {manageTouchMove(e)}} className='img-move-container'>
            {
                [...Array(18).keys()].map( (_, index) => {
                  const ref = createRef();
                refs.push(ref)
                return <img alt='' className='img-move' ref={ref} src={require(`../../public/asset/images/imagemove/${index}.jpg`)} />
                })
            }
        </div>

        <div className='soon-head-container'>
            <div className='grid-container'>
                <div className='flex justify-center sm:justify-normal col-start-1 col-end-5'>
                    <div className='soon-haas text-center sm:text-left'>We are a DESIGN STUDIO</div>
                </div>
                <div className='flex justify-center sm:justify-end col-start-1 col-end-5 sm:col-start-10 sm:col-end-13 '>
                    <div className='soon-pp mr-[1vw] sm:mr-[0.3vw] '>Website </div>
                    <div className='soon-haas'>Dropping Soon!</div>                    
                </div>
            </div>
        </div>

        <div className='soon-container'>
            <img src={LogoVertical} alt='' className='logo-soon'/>
        </div>

        <div className='soon-foot-container '>
            <div className='grid-container'>
                <div className='col-start-1 col-end-5'>
                    <div className='soon-haas text-center sm:text-left'>FOR PROJECT INQUIRIES OR</div>
                    <div className='flex align-middle justify-center sm:justify-normal'>
                        <div className='soon-haas mr-[1vw] sm:mr-[0.3vw]'>QUESTIONS</div>
                        <div className='soon-pp'>info@blankstudio.design</div>
                    </div>
                </div>
                <div className='hidden sm:flex sm:col-start-5 sm:col-end-9 lg:col-start-10 lg:col-end-13 justify-end pointer-events-none'>
                    <div>
                        <div className='soon-haas uppercase'>Based in</div>
                        <div className='soon-haas uppercase'>Jakarta, Indonesia</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  )
}

export default Soon