import React, { useEffect, useLayoutEffect, useState, createRef} from 'react'
import "./hero.css"
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Player from '../Player'

import LogoHorizontal from '../../public/asset/logo/navLogo.svg'

import { ReactComponent as Logo } from '../../public/asset/logo/navLogo.svg'

import { useLocation } from 'react-router';


gsap.registerPlugin(ScrollTrigger);  

function Hero() {

    useLayoutEffect(() => {
       
        let ctx = gsap.context(() => {

            // ScrollTrigger.create({
            //     animation: gsap.from('.logo', {
            //         scale: 10.2,
            //         y: '5.1vw',
            //         x: '43.8vw',
            //     }),
            //     scrub: 1.5,
            //     trigger: '.sub-hero-container',
            //     start: 'top 75%',
            //     end: 'top 75%',
            //     endTrigger: '.sub-hero-container',
            //     // markers: true,

            //     onLeave: () => {
            //         document.querySelector('.navbar-container').classList.add('navbar-mix');
            //         document.querySelector('.logo').classList.add('fill-white');
            //         document.querySelector('.logo').classList.remove('fill-black');

            //         const navLink = document.querySelectorAll('.nav-link');

            //         navLink.forEach((item) => {
            //             item.classList.add('text-white');
            //         })

            //         const links = document.querySelectorAll('#link');
            //         links.forEach((item) => {
            //             item.classList.add('link-underline-white');
            //             item.classList.remove('link-underline')
            //         })
            //     },
            //     onLeaveBack: () => {
            //         document.querySelector('.navbar-container').classList.remove('navbar-mix');
            //         document.querySelector('.logo').classList.add('fill-black');
            //         document.querySelector('.logo').classList.remove('fill-white');

            //         const navLink = document.querySelectorAll('.nav-link');
            //         navLink.forEach((item) => {
            //             item.classList.remove('text-white');
            //         })

            //         const links = document.querySelectorAll('#link');
            //         links.forEach((item) => {
            //             item.classList.remove('link-underline-white');
            //             item.classList.add('link-underline')
            //         })
            //     }
            // });

            // ScrollTrigger.create({
            //     animation: gsap.from('.logo-1', {
            //         scale: 6.96,
            //         y: '4.4vw',
            //         x: '41.2vw',
            //     }),
            //     scrub: 1.5,
            //     trigger: '.sub-hero-container',
            //     start: 'top 75%',
            //     end: '5% 75%',
            //     endTrigger: '.sub-hero-container',
            //     // markers: true,

            //     onLeave: () => {
            //         document.querySelector('.navbar-container').classList.add('navbar-mix');
            //         document.querySelector('.logo-1').classList.add('fill-white');
            //         document.querySelector('.logo-1').classList.remove('fill-black');
            //     },
            //     onLeaveBack: () => {
            //         document.querySelector('.navbar-container').classList.remove('navbar-mix');
            //         document.querySelector('.logo-1').classList.add('fill-black');
            //         document.querySelector('.logo-1').classList.remove('fill-white');
            //     },               
            // });

            // ScrollTrigger.create({
            //     animation: gsap.from('.logo-2', {
            //         scale: 3.4,
            //         y: '3.4vw',
            //         x: '32.9vw',
            //     }),
            //     scrub: 1,
            //     trigger: '.sub-hero-container',
            //     start: 'top 75%',
            //     end: '5% 75%',
            //     endTrigger: '.sub-hero-container',
            //     // markers: true,

            //     onLeave: () => {
            //         document.querySelector('.navbar-container').classList.add('navbar-mix');
            //         document.querySelector('.logo-2').classList.add('fill-white');
            //         document.querySelector('.logo-2').classList.remove('fill-black');
            //     },
            //     onLeaveBack: () => {
            //         document.querySelector('.navbar-container').classList.remove('navbar-mix');
            //         document.querySelector('.logo-2').classList.add('fill-black');
            //         document.querySelector('.logo-2').classList.remove('fill-white');
            //     },               
            // });

            // ScrollTrigger.create({
            //     animation: gsap.from('#links', {
            //         y: '12.4vw',
            //     }),
            //     scrub: 1.5,
            //     trigger: '.sub-hero-container',
            //     start: 'top 75%',
            //     end: 'top 75%',
            //     endTrigger: '.sub-hero-container',
            //     // markers: true,
            //     onLeave: () => {
            //         document.querySelector('.menu-btn-mobile').classList.add('text-white');
            //     },
            //     onLeaveBack: () => {
            //         document.querySelector('.menu-btn-mobile').classList.remove('text-white');
            //     }, 
            // });

            // ScrollTrigger.create({
            //     animation: gsap.from('.nav-mobile-container', {
            //         y: '15vw',
            //     }),
            //     scrub: 1.5,
            //     trigger: '.sub-hero-container',
            //     start: 'top 75%',
            //     end: '5% 75%',
            //     endTrigger: '.sub-hero-container',
            // });

            ScrollTrigger.create({
                trigger: '#text',
                start: "top top",
                end: "bottom top",
                pin: '.hero-text-container',
                pinSpacing: false,
                scrub: 5,
            });


            // HORIZONTAL SCROLL
            // let pinWrap = document.querySelector(".pin-wrap");
            // let pinWrapWidth = pinWrap.offsetWidth;
            // let horizontalScrollLength = pinWrapWidth - window.innerWidth;

            // gsap.to(".pin-wrap", {
            //     scrollTrigger: {
            //         scrub: true,
            //         trigger: "#sectionPin",
            //         pin: true,
            //         start: "top top",
            //         end: pinWrapWidth,
            //         markers: true,
            //     },
            //     x: -horizontalScrollLength,
            //     ease: "none",
            // });


            // BACKGROUND CHANGE
            // const target = document.querySelector("[data-bgchange]");

            // gsap.fromTo(target, { 
            //     backgroundColor: "#000000",
            // },
            // {
            // backgroundColor: "#FFFEF9",
            //     scrollTrigger: {
            //         trigger: "#sectionPin",
            //         start: 'top top',
            //         end: '#thirdSection',
            //         end: pinWrapWidth,
            //         scrub: true,
            //     }
            // });

        });

        return () => ctx.revert();

    }, []);

    // const { pathname } = useLocation();
    // const [nav, setNav] = useState(false);
    // const handleNav = () => {
    //     setNav(!nav)
    // }

    // useEffect(() => {
    //     const btn = document.querySelector('#btn-mobile');
    //     btn.addEventListener("touchend", function(e){
    //         const initialText = 'Menu';
    //         e.stopImmediatePropagation();

    //         if (btn.textContent.toLowerCase().includes(initialText.toLowerCase())) {
    //             btn.textContent = 'Close';
    //             document.body.style.overflow = 'hidden';
    //         } else {
    //             btn.textContent = initialText;
    //             document.body.style.overflow = '';
    //         }
    //     })
    // }, [])


    const [imgSrc, setImgSrc] = useState('01.png');

    // window.addEventListener("load", (e) => {
    //     const body = document.querySelector('.hero-container')
    //     const label = document.querySelector('#label')

    //     let source = [
    //         '01.png',
    //         '02.png',
    //         '03.png',
    //         '04.png',
    //         '05.png',
    //         '06.png',
    //         '07.png',
    //         '08.png'
    //     ]

    //     const imageCache = {};
    //     source.forEach((src) => {
    //         const img = new Image();
    //         img.src = require(`../../public/asset/images/hero-2/${src}`);
    //         imageCache[src] = img;
    //     });

    //     let FPS = 60

    //     let width
    //     , height
    //     , velocityX = 1
    //     , velocityY = 1
    //     , pause = true
    //     , previousSource = 0
    //     ;

    //     let isChangingImage = false;

    //     function setImgSrcOnce(src) {
    //         if (!isChangingImage) {
    //             isChangingImage = true;

    //             // Call setImgSrc with the source
    //             setImgSrc(src);

    //             // After the setImgSrc has completed (possibly in a callback), reset the flag
    //             setTimeout(() => {
    //                 isChangingImage = false;
    //             }, 500);
    //         }
    //     }

    //     setInterval(() => {
    //         if (pause) return;

    //         let rect = label.getBoundingClientRect();

    //         let left = rect.x
    //         let top = rect.y

    //         if (left + rect.width >= width || left <= 0) {
    //             velocityX = -velocityX
    //             setImgSrcOnce(getRandomSource());

    //         }

    //         if (top + rect.height >= height || top <= 0) {
    //             velocityY = -velocityY
    //             setImgSrcOnce(getRandomSource());
    //         }

    //         label.style.left = rect.x + velocityX + 'px'
    //         label.style.top = rect.y + velocityY + 'px'

    //     }, 1000 / FPS)


    //     const reset = () => {
    //         width =
    //             window.innerWidth ||
    //             document.documentElement.clientWidth ||
    //             document.body.clientWidth
    //         ;

    //         height =
    //             window.innerHeight ||
    //             document.documentElement.clientHeight ||
    //             document.body.clientHeight
    //         ;

    //         pause =
    //             width <= label.getBoundingClientRect().width ||
    //             height <= label.getBoundingClientRect().height
    //         ;

    //         label.style.left = 'calc(50vw - 110px)'
    //         label.style.top = 'calc(50vh - 110px)'
    //         setImgSrc(source[0]);

    //     }

    //     const getRandomSource = () => {
    //         let currentSource = -1
            
    //         do {
    //             currentSource = Math.floor(Math.random() * source.length)
    //         } while (previousSource === currentSource);
            
    //         previousSource = currentSource;
    //         return source[currentSource]
    //     }

    //     reset();   

    //     window.addEventListener('resize', reset, true)
    // });

    useEffect(()=> {

        // const body = document.querySelector('.hero-container')
        // const label = document.querySelector('#label')

        const source = [
            '01.png',
            '02.png',
            '03.png',
            '04.png',
            '05.png',
            '06.png',
            '07.png',
            '08.png'
        ]

        let x = 0,
        y = 0,
        dirX = 1,
        dirY = 1,
        switchX = 0,
        switchY = 0;
        const speed = 2;

        let isChangingImage = false;

        let dvd = document.querySelector('#label')
        const canvas = document.querySelector('.bounce-container')

        let prevColorChoiceIndex = 0;

        let dvdWidth = dvd.clientWidth;
        let dvdHeight = dvd.clientHeight;

        console.log(dvdWidth);

        function getNewRandomColor() {
            const currentSource = [...source]
            currentSource.splice(prevColorChoiceIndex,1)
            const colorChoiceIndex = Math.floor(Math.random() * currentSource.length);
            prevColorChoiceIndex = colorChoiceIndex<prevColorChoiceIndex?colorChoiceIndex:colorChoiceIndex+1;
            const colorChoice = currentSource[colorChoiceIndex];
            
            return colorChoice;
        }

        function animate() {
            const screenHeight = canvas.clientHeight;
            const screenWidth = canvas.clientWidth;

            if ((y + dvdHeight >= screenHeight && switchY == 0 && dirY > 0) || y < 0) {
                dirY *= -1;
                setImgSrc(getNewRandomColor());
                switchY = 1;
            }
            if ((x + dvdWidth >= screenWidth && switchX == 0 && dirX > 0)|| x < 0) {
                dirX *= -1;
                setImgSrc(getNewRandomColor());
            }
            if(y + dvdHeight < screenHeight) {
                switchY = 0;
            }
            if(x + dvdWidth < screenWidth) {
                switchX = 0;
            }
            if(y + dvdHeight > screenHeight && dirY < 0) {
                y = screenHeight - dvdHeight;
            }
            if(x + dvdWidth > screenWidth && dirX < 0) {
                x = screenWidth - dvdWidth;
            }  

            x += dirX * speed;
            y += dirY * speed;
            dvd.style.left = x + "px";
            dvd.style.top = y + "px";

            window.requestAnimationFrame(animate);
        }

        window.requestAnimationFrame(animate);

    }, [])

  return (
    <div>
        <div className='bounce-container'>
            <img id='label' src={require(`../../public/asset/images/hero-2/${imgSrc}`)}/>
        </div>
        <div className='hero-container'>

            

            {/* <div className='hero-logo-container'>
                <img src={LogoHorizontal} alt='' className='hero-logo'/>
            </div> */}


            {/* <div className='hero-gallery'>
                <div className='block block-1'></div>
                <div className='block block-2'></div>
                <div className='block block-3'></div>
                <div className='block block-4'></div>
                <div className='block block-5'></div>
                <div className='block block-6'></div>
                <div className='block block-7'></div>
                <div className='block block-8'></div>
                <div className='block block-9'></div>
                <div className='block block-10'></div>
                <div className='block block-11'></div>
            </div>

            <div className='hero-logo-container'>
                <img src={LogoHorizontal} alt='' className='hero-logo'/>
            </div> */}
        
    
        {/* <div id='text' className='w-full h-screen relative mix-blend-difference'>
            <div className='hero-text-container'>
                <div className='hero-text-pp'>
                    Multidisciplinary creative studio based in Indonesia. Crafting 
                    brands & visions one blank canvas at a time.
                </div>
            </div>
        </div> */}

            <div className='hero-body-container'>
                <div className='grid-container'>
                    <div className='col-start-1 col-end-5 sm:col-end-4 lg:col-end-5'>
                        {/* <div className='hidden sm:flex hero-text-main pb-[1.5vw] lg:pb-[1vw] uppercase'>Blank Studio</div> */}
                        <p className='hero-text-main'>We’re busy working on our new website, 
                                                    which will be launched shortly. In the meantime, 
                                                    feel free to follow us on&nbsp;
                            <Link className='underline decoration-[#c8c5b8]' to='https://www.instagram.com/blankstudio.design' target='_blank'>
                            Instagram
                            </Link>.
                        </p>
                        <p className='hero-text-main pt-[3vw] sm:pt-[1.5vw] lg:pt-[1vw]'>
                            Blank Studio is a small team of creative humans all about pushing boundaries. 
                            We help create the brands, products and experiences of tomorrow. We’re not 
                            just a design studio, we’re the driving force behind transformative creative 
                            journeys, where design knows no limits.
                        </p>
                    </div>
                    <div className='pt-[3vw] sm:pt-0 col-start-1 col-end-3 sm:col-start-5 sm:col-end-7 lg:col-start-6 lg:col-end-8'>
                        <div className='hero-text-main pb-[3vw] sm:pb-[1.5vw] lg:pb-[1vw] uppercase'>Services</div>
                        <p className='hero-text-main'>Art Direction<br/>
                                                        Logo Design<br/>
                                                        Brand Guidelines<br/>
                                                        Brand Strategy <br/>
                                                        Visual Identity Design <br/>
                                                        Packaging <br/>
                                                        Graphic Design <br/>
                                                        Motion Graphics<br/>
                                                        Illustration <br/>
                                                        Books & Editorial<br/>
                                                        UX/UI Design <br/>
                                                        Web Development <br/>
                                                        Social Media <br/>
                        </p>
                    </div>
                    <div className='pt-[3vw] sm:pt-0 col-start-3 col-end-5 sm:col-start-7 sm:col-end-9 lg:col-start-8 lg:col-end-10'>
                        <div className='hero-text-main pb-[3vw] sm:pb-[1.5vw] lg:pb-[1vw] uppercase'>Clients</div>
                        <div className='hero-text-main'>11th Space<br/>
                                                        Acaii Tea<br/>
                                                        Astana Capital<br/>
                                                        Cinta Laura<br/>
                                                        Grainsly<br/>
                                                        Kiyokawa<br/>
                                                        Pacific Parking<br/>
                                                        Project 1945<br/>
                                                        Otherlands<br/>
                                                        Ramengvrl<br/>
                                                        Shortlyst<br/>
                                                        Studia Academy<br/>
                                                        Zhuma<br/>
                        </div>
                    </div>
                    <div className='pt-[3vw] sm:pt-[4.5vw] lg:pt-0 col-start-1 col-end-3 sm:col-start-5 sm:col-end-7 lg:col-start-10 lg:col-end-12'>
                        <div className='hero-text-main pb-[3vw] sm:pb-[1.5vw] lg:pb-[1vw] uppercase'>Contact</div>
                        <p className='hero-text-main'>
                            Blank Studio<br/>
                            Pantai Indah Kapuk<br/>
                            Jakarta, Indonesia
                        </p>
                        <p className='hero-text-main pt-[3vw] sm:pt-[1.5vw] lg:pt-[1vw]'>+62 812 8202 7430<br/>
                            <Link className='underline decoration-[#c8c5b8]' to='mailto:info@blankstudio.design'>
                                info@blankstudio.design
                            </Link>
                        </p>
                        <p className='hero-text-main pt-[3vw] sm:pt-[1.5vw] lg:pt-[1vw]'>
                            <Link className='underline decoration-[#c8c5b8]' to='https://www.instagram.com/blankstudio.design' target='_blank'>
                                Instagram
                            </Link><br/>
                            <Link className='underline decoration-[#c8c5b8]' to='https://www.behance.net/blankstudio-design' target='_blank'>
                                Behance
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            <div className='hero-logo-container flex-1'>
                <img src={LogoHorizontal} alt='' className='hero-logo'/>
            </div>
        </div>


        
        {/* <div className='w-full bg-black' data-bgchange> */}
            {/* <div className='sub-hero-container'>
                <div className='grid-container'>
                    <div className='sub-hero-text'>
                        <div className='hero-text-pp-s'>
                            We're a small team of creative humans who create work we're proud of for people and brands we 
                            believe in. With collaboration and experimentation at the heart of every project, we are 
                            committed to discover new paths for our clients, pushing boundaries and exploring new 
                            possibilities.
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className='w-full h-screen'></div> */}


            {/* <div id="sectionPin" className='hero-slider-container'>
                <div className='pin-wrap'>
                    <img alt='' className='img-x' src={require(`../../public/asset/images/imagemove/1.jpg`)} />
                    <img alt='' className='img-x' src={require(`../../public/asset/images/imagemove/1.jpg`)} />
                    <img alt='' className='img-x' src={require(`../../public/asset/images/imagemove/1.jpg`)} />
                    <img alt='' className='img-x' src={require(`../../public/asset/images/imagemove/1.jpg`)} />
                    <img alt='' className='img-x' src={require(`../../public/asset/images/imagemove/1.jpg`)} />
                </div>
            </div> */}
            {/* <div id='third-section' className='w-full h-screen'></div>  */}
        {/* </div> */}


    
            {/* <div className='slider'>a */}
                {/* <div className='slider-wrapper'> */}
                    {/* <div className='slide'>
                        <img alt='' className='img-x' src={require(`../../public/asset/images/imagemove/1.jpg`)} />
                    </div> */}
                {/* </div> */}
            {/* </div> */}



    </div>
  )
}

export default Hero