import React from 'react'
import NavBar from '../../components/NavBar'
import Birdnest from '../../components/BirdNest'

function birdnest() {
  return (
    <>
        <NavBar />
        <Birdnest />
    </>
  )
}

export default birdnest