// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.explore-container {
    height: 100vh;
    margin: 0;
    overflow: hidden;
}

#explore {
    height: 90vmax;
    width: 80vmax;
    position: relative;
}
@media (min-width: 1024px) {
    #explore{
        height: 100vmax;
        width: 140vmax;
    }
}
  
.explore-block {
    position: absolute;
    height: auto;
    z-index: 1;
    transition: transform 800ms ease;
}

.explore-block:hover {
    z-index: 2;
    transform: scale(1.3);
}

.explore-block:nth-child(1) {
    width: 18vw;
    top: 20%;
    left: 30%;
}
.explore-block:nth-child(2) {
    width: 17vw;
    top: 10%;
    left: 50%;
}
.explore-block:nth-child(3) {
    width: 20vw;
    top: 28%;
    left: 70%;
}
.explore-block:nth-child(4) {
    width: 19vw;
    top: 55%;
    left: 47%;
}
.explore-block:nth-child(5) {
    width: 16vw;
    top: 41%;
    left: 23%;
}
.explore-block:nth-child(6) {
    width: 16vw;
    top: 32%;
    left: 42%;
}
.explore-block:nth-child(7) {
    width: 14vw;
    top: 40%;
    left: 66%;
}
.explore-block:nth-child(8) {
    width: 18vw;
    top: 68%;
    left: 77%;
}
.explore-block:nth-child(9) {
    width: 10vw;
    top: 7%;
    left: 12%;
}`, "",{"version":3,"sources":["webpack://./src/components/ProjectExplore/projectexplore.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,kBAAkB;AACtB;AACA;IACI;QACI,eAAe;QACf,cAAc;IAClB;AACJ;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,gCAAgC;AACpC;;AAEA;IACI,UAAU;IACV,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,QAAQ;IACR,SAAS;AACb;AACA;IACI,WAAW;IACX,QAAQ;IACR,SAAS;AACb;AACA;IACI,WAAW;IACX,QAAQ;IACR,SAAS;AACb;AACA;IACI,WAAW;IACX,QAAQ;IACR,SAAS;AACb;AACA;IACI,WAAW;IACX,QAAQ;IACR,SAAS;AACb;AACA;IACI,WAAW;IACX,QAAQ;IACR,SAAS;AACb;AACA;IACI,WAAW;IACX,QAAQ;IACR,SAAS;AACb;AACA;IACI,WAAW;IACX,QAAQ;IACR,SAAS;AACb;AACA;IACI,WAAW;IACX,OAAO;IACP,SAAS;AACb","sourcesContent":[".explore-container {\n    height: 100vh;\n    margin: 0;\n    overflow: hidden;\n}\n\n#explore {\n    height: 90vmax;\n    width: 80vmax;\n    position: relative;\n}\n@media (min-width: 1024px) {\n    #explore{\n        height: 100vmax;\n        width: 140vmax;\n    }\n}\n  \n.explore-block {\n    position: absolute;\n    height: auto;\n    z-index: 1;\n    transition: transform 800ms ease;\n}\n\n.explore-block:hover {\n    z-index: 2;\n    transform: scale(1.3);\n}\n\n.explore-block:nth-child(1) {\n    width: 18vw;\n    top: 20%;\n    left: 30%;\n}\n.explore-block:nth-child(2) {\n    width: 17vw;\n    top: 10%;\n    left: 50%;\n}\n.explore-block:nth-child(3) {\n    width: 20vw;\n    top: 28%;\n    left: 70%;\n}\n.explore-block:nth-child(4) {\n    width: 19vw;\n    top: 55%;\n    left: 47%;\n}\n.explore-block:nth-child(5) {\n    width: 16vw;\n    top: 41%;\n    left: 23%;\n}\n.explore-block:nth-child(6) {\n    width: 16vw;\n    top: 32%;\n    left: 42%;\n}\n.explore-block:nth-child(7) {\n    width: 14vw;\n    top: 40%;\n    left: 66%;\n}\n.explore-block:nth-child(8) {\n    width: 18vw;\n    top: 68%;\n    left: 77%;\n}\n.explore-block:nth-child(9) {\n    width: 10vw;\n    top: 7%;\n    left: 12%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
