import React, { useEffect, useRef, useState } from 'react'
import "./project.css"
import { projectData } from '../../helpers/projectdata'
import { Link } from 'react-router-dom';

function Project() {

    const options = ["All Works", "Brand Identity", "Packaging", "Web Development"]

    const [isActive, setIsActive] = useState(false);
    const [selected, setSelected] = useState('All Works');

    let menuRef = useRef();

    useEffect(() => {
        let handler = (e)=>{
            if(!menuRef.current.contains(e.target)){
                setIsActive(false);
            }      
        };

        document.addEventListener("mousedown", handler);

        return() =>{
            document.removeEventListener("mousedown", handler);
        }
    });

    const [data, setData] = useState([]);
    const countProject = projectData.length;

    useEffect(() => {
        setData(projectData);
    }, []);

    const [isOpen, setIsOpen] = useState(false);

    return (
    <div>
        <div className='page-section'>
            <div className='project-container '>
                <div className='grid-container pt-[24vw] sm:pt-[1.5vw]'>
                    <div className='project-heading-container'>
                        <h1 className='project-heading'>Work</h1>
                        <p className='project-heading-subscript'>{countProject}</p>
                    </div>
                    <div className='project-filter-container'>
                        <p className='project-filter mr-0 sm:mr-[1vw]'>Filter :</p>
                        {/* <p className='project-filter filter-underline'>Web Development</p> */}
                        <div className='dropdown' ref={menuRef}>
                            <div className='dropdown-btn filter-underline' onClick={(e) => setIsActive(!isActive)}>
                                {selected}
                            </div>
                            <div className={`dropdown-content bg-green-200 ${isActive ? 'active' : 'inactive'}`}>
                                {options.map((option) => 
                                    <div className='dropdown-item' onClick={(e) => {
                                        setSelected(option);
                                        setIsActive(false);
                                    }}>{option}</div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* <div className='project-button-container'>
                        <div className="radio-input">
                            <label>
                                <input type="radio" id="value-1" name="value-radio" value="value-1" defaultChecked={true}/>
                                <span>Grid</span>
                            </label>
                            <label className='px-[1.5vw]'>
                                <input type="radio" id="value-2" name="value-radio" value="value-2" />
                                <span>Explore</span>
                            </label>
                            <label>
                                <input type="radio" id="value-3" name="value-radio" value="value-3" />
                                <span>List</span>
                            </label>
                            <span className="selection"></span>
                        </div>
                    </div> */}
                </div>
                <div className='line pt-[3vw] sm:pt-[1vw] lg:pt-[1vw]'>
                    <div className='black-line'></div>
                </div>
                <div className='grid-container pt-[3vw] sm:pt-[1vw] lg:pt-[1vw]'>
                    {
                        data.map((item) =>
                            <div className='project-block'>
                                <Link to={item.url}>
                                    <div className='project-img-container'>
                                        <img src={require(`../../public/asset/images/works/${item.imgSrc1}`)} className='project-img img-1' alt={item.title} />
                                        <img src={require(`../../public/asset/images/works/${item.imgSrc2}`)} className='project-img img-2' alt={item.title} />
                                        <img src={require(`../../public/asset/images/works/${item.imgSrc3}`)} className='project-img img-3' alt={item.title} />
                                        <img src={require(`../../public/asset/images/works/${item.imgSrc4}`)} className='project-img img-4' alt={item.title} />
                                        <img src={require(`../../public/asset/images/works/${item.imgSrc5}`)} className='project-img img-5' alt={item.title} />
                                    </div>
                                    <div className='title-container'>
                                        <p className='title-haas uppercase'>{item.title}</p>
                                        <p className='title-haas '>({item.category})</p>
                                    </div>
                                </Link>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    </div>
    )
}

export default Project