import React from 'react'
import NavBar from '../../components/NavBar'
import YukishimizuGin from '../../components/YukishimizuGin'

function yukishimizu() {
  return (
    <>
        <NavBar />
        <YukishimizuGin />
    </> 
  )
}

export default yukishimizu