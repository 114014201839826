import React from 'react'
import NavBar from '../../components/NavBar'
import EleventhSpace from '../../components/11thspace'

function elevethspace() {
  return (
    <>
        <NavBar />
        <EleventhSpace />
    </>
  )
}

export default elevethspace