import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import Hero from '../../components/Hero'
import Hero2 from '../../components/Hero2'
import SelectedWorks from '../../components/SelectedWorks'
import NavBar from '../../components/NavBar'
import Transition from '../../components/Transition'
import { gsap } from 'gsap'

const Home = () => {
    const home = gsap.timeline();
    const [loaded, setLoaded] = useState(false)
    useEffect(()=>{
      console.log('Home videoReady', loaded)
    },[loaded])

  return (
    <>
      {/* <Transition timeline={home} resume={loaded}/> */}
      {/* <NavBar /> */}
      <Hero />
      {/* <Hero2 /> */}
      {/* <SelectedWorks callbackVideo={() => setLoaded(true)}/>  */}
      {/* <Footer /> */}
    </>
  )
}

export default Home