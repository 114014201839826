import React from 'react'
import NavBar from '../../components/NavBar'
import AintNoMotherfvckers from '../../components/AintNoMotherfvckers'

function aintnomotherfvckers() {
    return (
    <>
        <NavBar />
        <AintNoMotherfvckers />
    </>
    )
}

export default aintnomotherfvckers