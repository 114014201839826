import React, { useEffect, useState } from 'react'
import "./radiobutton.css"
import { useNavigate } from 'react-router-dom';

function RadioButton(props) {
    const [routes, setRoutes] = useState("");
    const navigate = useNavigate();

    useEffect(()=> {
        routes == "grid" && navigate('/work');
        routes == "explore" && navigate('/explore')
        routes == "list" && navigate('/list')
    },[routes])
    
    return (
    <div className='project-button-container'>
        <div className="radio-input">
            <label>
                <input type="radio" id="value-1" name="value-radio" value="value-1" defaultChecked={props.defaultChecked == "grid"} onChange={()=> setRoutes("grid")}/>
                <span>Grid</span>
            </label>
            <label className='px-[1.5vw]'>
                <input type="radio" id="value-2" name="value-radio" value="value-2" defaultChecked={props.defaultChecked == "explore"} onChange={()=> setRoutes("explore")}/>
                <span>Explore</span>
            </label>
            <label>
                <input type="radio" id="value-3" name="value-radio" value="value-3" defaultChecked={props.defaultChecked == "list"} onChange={()=> setRoutes("list")}/>
                <span>List</span>
            </label>
            <span className="selection"></span>
        </div>
    </div>
  )
}

export default RadioButton