import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import "./selectedworks.css"
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Arrow from '../../public/asset/Icons/ARROW_BLANK_STUDIO.svg'
import Taiyo from '../../public/asset/images/selectedworks/SW_TAIYO.jpg'
import EleventhSpace from '../../public/asset/images/selectedworks/SW_11THSPACE.jpg'
import Otherlands from '../../public/asset/images/selectedworks/SW_OTHERLANDS.jpg'
import Tigerkhin from '../../public/asset/images/selectedworks/SW_TIGERKHIN.jpg'
import Bali from '../../public/asset/images/selectedworks/SW_BALIPOLOCLUB.jpg'
import Birdnest from '../../public/asset/images/selectedworks/SW_BIRDNEST.jpg'
import Gin from '../../public/asset/images/works/yukishimizu/YUKI_FIVE.jpg'
import Player from '../Player'

import Img1 from '../../public/asset/images/works/tigerkhin/cover/TIGERKHIN_C_3.jpg'
import Img2 from '../../public/asset/images/works/project1945/two/cover/PROJECT1945_C_1.jpg'
import Img3 from '../../public/asset/images/works/aintnomf/cover/AINTNOMF_C_1.jpg'
import Img4 from '../../public/asset/images/works/balipoloclub/cover/BPC_C_2.jpg'


function SelectedWorks(props) {
    
    const [ramenVideo, setRamenVideo] = useState(false);
    const [projectVideo, setProjectVideo] = useState(false);
    const [project2Video, setProject2Video] = useState(false);
    const [tabooVideo, setTabooVideo] = useState(false);
    const [kiehlVideo, setKiehlVideo] = useState(false);
    const [kiehl2Video, setKiehl2Video] = useState(false);
    const [makankuVideo, setMakankuVideo] = useState(false);
    const [makanku2Video, setMakanku2Video] = useState(false);

    const videoReady = ramenVideo && projectVideo && kiehlVideo && makankuVideo && project2Video && tabooVideo && kiehl2Video && makanku2Video;


    const [otherlandsPhoto, setOtherlandsPhoto] = useState(false);
    const [birdNestPhoto, setBirdNestPhoto] = useState(false);
    const [birdNestPhoto2, setBirdNestPhoto2] = useState(false);
    const [baliPhoto, setBaliPhoto] = useState(false);
    const [eleventhSpacePhoto, seteEleventhSpacePhoto] = useState(false);
    const [eleventhSpacePhoto2, seteEleventhSpacePhoto2] = useState(false);
    const [ginPhoto, setGinPhoto] = useState(false);
    const [ginPhoto2, setGinPhoto2] = useState(false);
    const [taiyoPhoto, setTaiyoPhoto] = useState(false);
    const [tigerkhinPhoto, setTigerkhinPhoto] = useState(false);

    const photoReady = otherlandsPhoto && birdNestPhoto && birdNestPhoto2 && baliPhoto && eleventhSpacePhoto && eleventhSpacePhoto2 && ginPhoto && ginPhoto2 && taiyoPhoto && tigerkhinPhoto;
    console.log('photoReady', photoReady)

    console.log('videoReady', videoReady)
    photoReady && videoReady && props.callbackVideo();
    // useEffect(() => {
    //     let ctx = gsap.context(() => {
    //         ScrollTrigger.create({
    //             trigger: '#selected',
    //             start: 'top 7.5%',
    //             pin: '.sticky',
    //             pinSpacing: false,
    //             markers: true,
    //         });
    //     }, main);
    //     return () => ctx.revert();        
    // }, [])

    // useEffect(() => {
    //     gsap.fromTo(".work-container div", {
    //         opacity: 0
    //     },
    //     {
    //         opacity: 1,
    //         delay: 4.5,
    //         ease: "power3.InOut",
    //         stagger: {
    //             amount: .5
    //         }
    //     });

    // },[])
    useEffect(() => {
        console.log('SelectedWorks');
    },[])    
    return (
    <div className='selected-section'>
        
        <div className='selected-container '>

            {/* <div className='line'>
                <div className='black-line'></div>
            </div> */}
            

            {/* <div className='grid-container pb-[2.5vw]'>
                <div className='subtext-container'>
                    
                    <p className='subtext-haas'>
                        Blank Studio has a distinct focus on brand based solutions. We're a small team of creative humans 
                        who create work we're proud of for people and brands we believe in. Our design philosophy is rooted
                        in a fundamental belief that the beauty of a design is derived from its functionality. With collaboration
                        and experimentation at the heart of every project, we are committed to discover new paths for our
                        clients, pushing boundaries and exploring new possibilities to create new experiences.
                    </p> */}
                    {/* <p className='subtext-haas '>
                        We're a small team of creative humans 
                        who create work we're proud of for people and brands we believe in. With collaboration
                        and experimentation at the heart of every project, we are committed to discover new paths for our
                        clients, pushing boundaries and exploring new possibilities to create new experiences.
                    </p> */}
                {/* </div>   */}
            {/* </div> */}
            

            <div className='line'>
                <div className='black-line'></div>
            </div>
            <div className='grid-container pb-[4vw]'>
                <div className='selected-bar'>
                    <p className='section-haas uppercase'>Selected works</p>
                    {/* <Link to='/work' className='flex'>
                        <img src={Arrow} alt='arrow' className='arrow-right'/>
                        <p className='section-haas uppercase'>View all works</p>
                    </Link> */}
                </div>
            </div>

            <div className='grid-container'>
                <div className=' col-span-1 bg-red-100'>1</div>
                <div className=' col-span-1 bg-red-100'>2</div>
                <div className=' col-span-1 bg-red-100'>3</div>
                <div className=' col-span-1 bg-red-100'>4</div>
                <div className=' col-span-1 bg-red-100'>5</div>
                <div className=' col-span-1 bg-red-100'>6</div>
                <div className=' col-span-1 bg-red-100'>7</div>
                <div className=' col-span-1 bg-red-100'>8</div>
                <div className=' col-span-1 bg-red-100'>9</div>
                <div className=' col-span-1 bg-red-100'>10</div>
                <div className=' col-span-1 bg-red-100'>11</div>
                <div className=' col-span-1 bg-red-100'>12</div>
            </div>

            
            {/* <div className='h-screen'>
                <div className='selected-heading pb-[0.5vw] uppercase'>Featured Work</div>


            </div> */}
            
            {/* <div className='grid-container py-[4vw] sm:py-[1.5vw]'>
                <div className='work-container'>
                    <div>
                        <Link to='/'>
                            <Player 
                                src={require('../../public/asset/images/selectedworks/SW_AINT_NO_MF_VID.mp4')} 
                                onLoad={()=>console.log('onLoad')}
                                onLoadedData={()=>{setRamenVideo(true); console.log('setRamenVideo')}}
                                onLoadStart={()=>console.log('onLoadStart')} />
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>Ramengvrl</p>
                            <p className='text-haas'>
                                Title typography with poster design for 'Ain't No Motherf**kers', a single by RAMENGVRL 
                                featuring pH-1.
                            </p>
                        </div>
                    </div>
                    <div>
                        <Link>
                            <Player 
                                src={require('../../public/asset/images/selectedworks/SW_TABOO TUESDAY_VID.mp4')}
                                onLoad={()=>console.log('onLoad')}
                                onLoadedData={()=>{setTabooVideo(true); console.log('setTabooVideo')}}
                                onLoadStart={()=>console.log('onLoadStart')} />
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>Taboo Tuesday</p>
                            <p className='text-haas'>
                                An inclusive brand for a safe space to discuss all things considered taboo.
                            </p>
                        </div>
                    </div>
                    <div>
                        <Link>
                            <img src={Otherlands} alt='Otherlands' onLoad={()=>{setOtherlandsPhoto(true); console.log('setOtherlandsPhoto')}}/>
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>Otherlands</p>
                            <p className='text-haas'>
                                Brand identity for a boutique production servicing in art, music, entertainment, and 
                                film industries.
                            </p>
                        </div>
                    </div>

                    <div className='lg:hidden'>
                        <Link to='/'>
                            <Player 
                                src={require('../../public/asset/images/selectedworks/SW_PROJECT1945_VID.mp4')}
                                onLoad={()=>console.log('onLoad')}
                                onLoadedData={()=>{setProjectVideo(true); console.log('setProjectVideo')}}
                                onLoadStart={()=>console.log('onLoadStart')} />
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>Project 1945</p>
                            <p className='text-haas'>
                                Art direction, identity, product, photography, and digital media designed for Project 1945, 
                                a perfume brand inspired by the beauty of Indonesia.
                            </p>
                        </div>
                    </div>
                    <div className='lg:hidden'>
                        <Link to='/'>
                            <img src={Birdnest} alt='Bird Nest' onLoad={()=>{setBirdNestPhoto(true); console.log('setBirdNestPhoto')}}/>
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>Acaii Tea</p>
                            <p className='text-haas'>
                                Box and sticker label design for bird's nest soup product by Acaii Tea Indonesia.
                            </p>
                        </div>
                    </div>
                    <div className='lg:hidden'>
                        <Link to='/'>
                            <Player 
                                src={require('../../public/asset/images/selectedworks/SW_SUKAKAMU.mp4')}
                                onLoad={()=>console.log('onLoad')}
                                onLoadedData={()=>{setKiehlVideo(true); console.log('setKiehlVideo')}}
                                onLoadStart={()=>console.log('onLoadStart')} />
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>Suka Kamu</p>
                            <p className='text-haas'>
                                Music video logotype for Suka Kamu by Cinta Laura Kiehl.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='work-container'>
                    <div>
                        <Link>
                            <img alt='Taiyo' src={Taiyo} onLoad={()=>{setTaiyoPhoto(true); console.log('setTaiyoPhoto')}}/>
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>Taiyo</p>
                            <p className='text-haas'>
                                Industrial packaging and engraving design for Taiyo diamond cutting wheel.
                            </p>
                        </div>
                    </div>
                    <div>
                        <Link to='/'>
                            <img src={Tigerkhin} alt='Tigerkhin' onLoad={()=>{setTigerkhinPhoto(true); console.log('setTigerkhinPhoto')}}/>
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>Tigerkhin</p>
                            <p className='text-haas'>
                                Contemporary brand identity for a company serving Thai street food including a custom typeface 
                                logo, food packaging and labels to social media templates.
                            </p>
                        </div>
                    </div>
                    <div>
                        <Link to='/'>
                            <img src={Bali} alt='Bali Polo Club' onLoad={()=>{setBaliPhoto(true); console.log('setBaliPhoto')}}/>
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>Bali Polo Club</p>
                            <p className='text-haas'>
                                The first polo arena and beach polo club in Bali, Indonesia                            
                            </p>
                        </div>
                    </div>

                    <div className='lg:hidden'>
                        <Link to='/'>
                            <img alt='11th Space' src={EleventhSpace} onLoad={()=>{seteEleventhSpacePhoto(true); console.log('seteEleventhSpacePhoto')}}/>
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>11th Space</p>
                            <p className='text-haas'>
                                Digital and social media design and management for 11th Space,
                                a collaborative space for entrepreneurs and innovative ideas.
                            </p>
                        </div>
                    </div>
                    <div className='lg:hidden'>
                        <Link to='/'>
                            <img src={Gin} alt='Yukishimizu Gin' onLoad={()=>{setGinPhoto(true); console.log('setGinPhoto')}}/>
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>Yukishimizu Gin</p>
                            <p className='text-haas'>
                                A Japanese craft gin designed to capture the spirit of Japan.
                            </p>
                        </div>
                    </div>
                    <div className='lg:hidden'>
                        <Link>
                            <Player 
                                src={require('../../public/asset/images/selectedworks/SW_KISAKUXMAKANKU_VID.mp4')}
                                onLoad={()=>console.log('onLoad')}
                                onLoadedData={()=>{setMakankuVideo(true); console.log('setMakankuVideo')}}
                                onLoadStart={()=>console.log('onLoadStart')} />
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>Kisaku x Makanku</p>
                            <p className='text-haas'>
                                Exhibition and collateral design for a pop-up collaboration event by Kisaku coffee shop and Makanku eatery.  
                            </p>
                        </div>
                    </div>
                </div>

                <div className='hidden lg:block work-container'>
                    <div>
                        <Link to='/'>
                            <Player 
                                src={require('../../public/asset/images/selectedworks/SW_PROJECT1945_VID.mp4')}
                                onLoad={()=>console.log('onLoad')}
                                onLoadedData={()=>{setProject2Video(true); console.log('setProject2Video')}}
                                onLoadStart={()=>console.log('onLoadStart')} />
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>Project 1945</p>
                            <p className='text-haas'>
                                Art direction, identity, product, photography, and digital media designed for Project 1945, 
                                a perfume brand inspired by the beauty of Indonesia.
                            </p>
                        </div>
                    </div>
                    <div>
                        <Link to='/'>
                            <img src={Birdnest} alt='Bird Nest' onLoad={()=>{setBirdNestPhoto2(true); console.log('setBirdNestPhoto2')}}/>
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>Acaii Tea</p>
                            <p className='text-haas'>
                                Box and sticker label design for bird's nest soup product by Acaii Tea Indonesia.
                            </p>
                        </div>
                    </div>
                    <div>
                        <Link to='/'>
                            <Player 
                                src={require('../../public/asset/images/selectedworks/SW_SUKAKAMU.mp4')} 
                                onLoad={()=>console.log('onLoad')}
                                onLoadedData={()=>{setKiehl2Video(true); console.log('setKiehl2Video')}}
                                onLoadStart={()=>console.log('onLoadStart')} />
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>Suka Kamu</p>
                            <p className='text-haas'>
                                Music video logotype for Suka Kamu by Cinta Laura Kiehl.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='hidden lg:block work-container'>
                    <div>
                        <Link to='/'>
                            <img alt='11th Space' src={EleventhSpace} onLoad={()=>{seteEleventhSpacePhoto2(true); console.log('seteEleventhSpacePhoto2')}}/>
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>11th Space</p>
                            <p className='text-haas'>
                                Digital and social media design and management for 11th Space,
                                a collaborative space for entrepreneurs and innovative ideas.
                            </p>
                        </div>
                    </div>
                    <div>
                        <Link to='/'>
                            <img src={Gin} alt='Yukishimizu Gin' onLoad={()=>{setGinPhoto2(true); console.log('setGinPhoto2')}}/>
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>Yukishimizu Gin</p>
                            <p className='text-haas'>
                                A Japanese craft gin designed to capture the spirit of Japan.
                            </p>
                        </div>
                    </div>
                    <div>
                        <Link>
                            <Player 
                                src={require('../../public/asset/images/selectedworks/SW_KISAKUXMAKANKU_VID.mp4')}
                                onLoad={()=>console.log('onLoad')}
                                onLoadedData={()=>{setMakanku2Video(true); console.log('setMakanku2Video')}}
                                onLoadStart={()=>console.log('onLoadStart')} />
                        </Link>
                        <div className='description-container'>
                            <p className='selected-haas'>Kisaku x Makanku</p>
                            <p className='text-haas'>
                                Exhibition and collateral design for a pop-up collaboration event by Kisaku coffee shop and Makanku eatery.  
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
    )
}

export default SelectedWorks