// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-container {
    width: 100%;
}

.list-container.active {
    visibility: visible;
    height: 10vw;
    transition: all 400ms ease-in-out;
}

.list-container.inactive {
    visibility: hidden;
    height: 0;
    transition: all 400ms ease-in-out;
}

.project-list-haas {
    font-family: 'NeueHaasGrotesk';
    font-weight: normal;
    font-style: normal;
    color: #000000;
    font-size: 1vw;
    line-height: 1vw;
    text-transform: uppercase;
}

.project-list-pp {
    font-family: 'PPEiko';
    font-weight: 100;
    font-style: normal;
    color: #000000;
    text-transform: uppercase;
    font-size: 1.2vw;
    line-height: 1.2vw;
}`, "",{"version":3,"sources":["webpack://./src/components/ProjectList/projectlist.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,iCAAiC;AACrC;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,iCAAiC;AACrC;;AAEA;IACI,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,cAAc;IACd,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".list-container {\n    width: 100%;\n}\n\n.list-container.active {\n    visibility: visible;\n    height: 10vw;\n    transition: all 400ms ease-in-out;\n}\n\n.list-container.inactive {\n    visibility: hidden;\n    height: 0;\n    transition: all 400ms ease-in-out;\n}\n\n.project-list-haas {\n    font-family: 'NeueHaasGrotesk';\n    font-weight: normal;\n    font-style: normal;\n    color: #000000;\n    font-size: 1vw;\n    line-height: 1vw;\n    text-transform: uppercase;\n}\n\n.project-list-pp {\n    font-family: 'PPEiko';\n    font-weight: 100;\n    font-style: normal;\n    color: #000000;\n    text-transform: uppercase;\n    font-size: 1.2vw;\n    line-height: 1.2vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
