import React, { useLayoutEffect, useState, useEffect } from 'react'
import { ReactComponent as Logo } from '../../public/asset/logo/navLogo.svg'
import { useLocation } from 'react-router';
import { Link } from "react-router-dom"
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import "./navbar.css"

gsap.registerPlugin(ScrollTrigger);  


function NavBar() {  
    const { pathname } = useLocation();
    const [nav, setNav] = useState(false);
    const handleNav = () => {
        setNav(!nav)
    }

    useEffect(() => {
        const btn = document.querySelector('#btn-mobile');
        btn.addEventListener("touchend", function(e){
            const initialText = 'Menu';
            e.stopImmediatePropagation();

            if (btn.textContent.toLowerCase().includes(initialText.toLowerCase())) {
                btn.textContent = 'Close';
                document.body.style.overflow = 'hidden';
            } else {
                btn.textContent = initialText;
                document.body.style.overflow = '';
            }
        })

    }, [])

    // ScrollTrigger.create({
    //     animation: gsap.from('.logo', {
    //         scale: 5,
    //         y: '50vh',
    //         yPercent: -50,
    //     }),
    //     scrub: true,
    //     trigger: 'navbar-container',
    //     start: 'top top',
    //     end: 'center center',
    //     endTrigger: '.navbar-container',
    //     markers: true,
    // });
    
    // useEffect(() => {
        // const btn = document.querySelector('#btn-mobile');
        // btn.addEventListener("touchend", function(e){
        //     const initialText = 'Menu';
        //     e.stopImmediatePropagation();

        //     if (btn.textContent.toLowerCase().includes(initialText.toLowerCase())) {
        //         btn.textContent = 'Close';
        //         document.body.style.overflow = 'hidden';
        //     } else {
        //         btn.textContent = initialText;
        //         document.body.style.overflow = '';
        //     }
        // })

        // gsap.fromTo(".nav-link-container, .section-bar", 
        // {
        //     opacity: 0,
        // },
        // {
        //     opacity: 1,
        //     ease: "power3.InOut",
        //     delay: 3,
        //     duration: 1,
        // });

        // gsap.fromTo(".logo", {
        //     scale: 8,
        //     y: "42.5vh",
        //     x: "43.5vw"
        // },
        // {
        //     scale: 1,
        //     y: 0,
        //     x: 0,
        //     ease: "power3.InOut",
        //     duration: 1,
        //     delay: 2
        // });

    // }, [])

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {

            // ScrollTrigger.create({
            //     animation: gsap.from('.logo', {
            //         scale: 10.2,
            //         y: '5.1vw',
            //         x: '43.8vw',
            //     }),
            //     scrub: 1,
            //     trigger: '.sub-hero-container',
            //     start: '1% 75%',
            //     end: '1% 75%',
            //     endTrigger: '.sub-hero-container',
            //     markers: true,

            //     onLeave: () => {
            //         document.querySelector('.navbar-container').classList.add('navbar-mix');
            //         document.querySelector('.logo').classList.add('fill-white');
            //         document.querySelector('.logo').classList.remove('fill-black');

            //         const navLink = document.querySelectorAll('.nav-link');

            //         navLink.forEach((item) => {
            //             item.classList.add('text-white');
            //         })

            //         const links = document.querySelectorAll('#link');
            //         links.forEach((item) => {
            //             item.classList.add('link-underline-white');
            //             item.classList.remove('link-underline')
            //         })
            //     },
            //     onLeaveBack: () => {
            //         document.querySelector('.navbar-container').classList.remove('navbar-mix');
            //         document.querySelector('.logo').classList.add('fill-black');
            //         document.querySelector('.logo').classList.remove('fill-white');

            //         const navLink = document.querySelectorAll('.nav-link');
            //         navLink.forEach((item) => {
            //             item.classList.remove('text-white');
            //         })

            //         const links = document.querySelectorAll('#link');
            //         links.forEach((item) => {
            //             item.classList.remove('link-underline-white');
            //             item.classList.add('link-underline')
            //         })
            //     }
            // });

            // ScrollTrigger.create({
            //     animation: gsap.from('.logo-1', {
            //         scale: 6.96,
            //         y: '4.4vw',
            //         x: '41.2vw',
            //     }),
            //     scrub: 1,
            //     trigger: '.sub-hero-container',
            //     start: 'top 75%',
            //     end: '5% 75%',
            //     endTrigger: '.sub-hero-container',
            //     // markers: true,

            //     onLeave: () => {
            //         document.querySelector('.navbar-container').classList.add('navbar-mix');
            //         document.querySelector('.logo-1').classList.add('fill-white');
            //         document.querySelector('.logo-1').classList.remove('fill-black');
            //     },
            //     onLeaveBack: () => {
            //         document.querySelector('.navbar-container').classList.remove('navbar-mix');
            //         document.querySelector('.logo-1').classList.add('fill-black');
            //         document.querySelector('.logo-1').classList.remove('fill-white');
            //     },               
            // });

            // ScrollTrigger.create({
            //     animation: gsap.from('.logo-2', {
            //         scale: 3.4,
            //         y: '3.4vw',
            //         x: '32.9vw',
            //     }),
            //     scrub: 1,
            //     trigger: '.sub-hero-container',
            //     start: 'top 75%',
            //     end: '5% 75%',
            //     endTrigger: '.sub-hero-container',
            //     // markers: true,

            //     onLeave: () => {
            //         document.querySelector('.navbar-container').classList.add('navbar-mix');
            //         document.querySelector('.logo-2').classList.add('fill-white');
            //         document.querySelector('.logo-2').classList.remove('fill-black');
            //     },
            //     onLeaveBack: () => {
            //         document.querySelector('.navbar-container').classList.remove('navbar-mix');
            //         document.querySelector('.logo-2').classList.add('fill-black');
            //         document.querySelector('.logo-2').classList.remove('fill-white');
            //     },               
            // });

            // ScrollTrigger.create({
            //     animation: gsap.from('#links', {
            //         y: '12.4vw',
            //     }),
            //     scrub: 1.5,
            //     trigger: '.sub-hero-container',
            //     start: 'top 75%',
            //     end: 'top 75%',
            //     endTrigger: '.sub-hero-container',
            //     // markers: true,
            //     onLeave: () => {
            //         document.querySelector('.menu-btn-mobile').classList.add('text-white');
            //     },
            //     onLeaveBack: () => {
            //         document.querySelector('.menu-btn-mobile').classList.remove('text-white');
            //     }, 
            // });
        });

        return () => ctx.revert();

    }, []);

  return (
    <div>
        <div className='navbar-container'>
            <div className='grid-container'>
                <div className='nav-logo-container'>
                    {/* <Link to='/home' className='hover-this'>
                        <Logo className='logo'/>
                    </Link>
                    <Link to='/home' className='hover-this'>
                        <Logo className='logo-1'/>
                    </Link>
                    <Link to='/home' className='hover-this'>
                        <Logo className='logo-2'/>
                    </Link> */}
                    <Link to='/home'>
                        <div id='link' className= 'nav-link'>
                            Blank Studio&reg;
                        </div>
                    </Link>
                </div>

                <div id='links' className='nav-link-work'>   
                    <Link to='/work'>
                        <div id='link' className= 'nav-link link-underline'>
                            Work
                        </div>
                    </Link>
                </div>
                <div id='links' className='nav-link-studio'>          
                    <Link to='/studio'>
                        <div id='link' className= 'nav-link link-underline'>
                            Studio
                        </div>
                    </Link>
                </div>
                <div id='links' className='nav-link-contact'>      
                    <Link to='/'>
                        <div id='link' className= 'nav-link link-underline'>
                            Contact
                        </div>
                    </Link>
                </div>

                {/* Mobile Menu Button */}
                <div className='nav-mobile-container' onTouchStart={handleNav}>
                    <div className='menu-btn-mobile' id='btn-mobile'>Menu</div>
                </div>
            </div>
        </div>

        {/* <div className={nav ? 'nav-menu-container' : 'hidden'}> */}
        <div className={nav ? 'nav-menu-container top-0' : 'nav-menu-container top-[-100vh]'}>
            {/* <div className='grid-container'>
                <div className='nav-logo-container'>
                    <Link href='/' className='hover-this'>
                        <Logo className='logo fill-red-50'/>
                    </Link>
                </div>
                <div className='nav-mobile-container' onTouchStart={handleNav}>
                    <div className='menu-btn-mobile text-red-50' id='btn-mobile'>Close</div>
                </div>
            </div> */}
            <ul className='mt-[34vh]'>
                {/* <li>
                    <Link to='/' className='mobile-menu'>Home</Link>
                </li> */}
                <li>
                    <Link to='/work' className='mobile-menu'>Work</Link>
                </li>
                <li className='py-[2vw]'>
                    <Link to='/studio' className='mobile-menu'>Studio</Link>
                </li>
                <li>
                    <Link to='/contact' className='mobile-menu'>Let's talk</Link>
                </li>
            </ul>
            <div className='w-full absolute bottom-[4vw] left-0 px-[4vw]'>
                <div className='flex justify-between'>  
                    <Link to='https://www.behance.net/blankstudio-design' target='_blank' className='menu-btn-mobile text-white'>Behance</Link>
                    <Link to='https://www.instagram.com/blankstudio.design' target='_blank' className='menu-btn-mobile text-white'>Instagram</Link>
                </div>
            </div>
        </div>

        {/* <div className='navbar-container'>
            <div className='grid-container'>
                <div className='nav-logo-container'>
                    <Link to='/' className='hover-this'>
                        <Logo className='logo fill-white'/>
                    </Link>
                </div>
                <div className='nav-link-container'>              
                    <Link to='/'>
                        <div className={pathname === '/work' ? 'nav-link link-underline-reverse' : 'nav-link link-underline'}>
                            Work
                        </div>
                    </Link>
                    <Link to='/' className='mx-[1.5vw]'>
                        <div className={pathname === '/studio' ? 'nav-link link-underline-reverse' : 'nav-link link-underline'}>
                            Studio
                        </div>
                    </Link>
                    <Link to='/'>
                        <div className={pathname === '/contact' ? 'nav-link link-underline-reverse' : 'nav-link link-underline'}>
                            Contact
                        </div>
                    </Link>
                </div>

                <div className='nav-mobile-container' onTouchStart={handleNav}>
                    <div className='menu-btn-mobile text-white' id='btn-mobile'>Menu</div>
                </div>
            </div>
        </div>

        <div className={nav ? 'nav-menu-container' : 'hidden'}>
            <div className='grid-container'>
                <div className='nav-logo-container'>
                    <Link href='/' className='hover-this'>
                        <Logo className='logo fill-red-50'/>
                    </Link>
                </div>
                <div className='nav-mobile-container' onTouchStart={handleNav}>
                    <div className='menu-btn-mobile text-red-50' id='btn-mobile'>Close</div>
                </div>
            </div>
            <ul className='mt-[34vh]'>
                <li>
                    <Link to='/' className='mobile-menu'>Home</Link>
                </li>
                <li>
                    <Link to='/work' className='mobile-menu'>Work</Link>
                </li>
                <li className='py-[2vw]'>
                    <Link to='/studio' className='mobile-menu'>Studio</Link>
                </li>
                <li>
                    <Link to='/contact' className='mobile-menu'>Let's talk</Link>
                </li>
            </ul>
            <div className='w-full fixed bottom-0 left-0'>
                <div className='flex justify-between px-[4vw] py-[5vw]'>  
                    <Link to='https://www.behance.net/blankstudio-design' target='_blank' className='menu-btn-mobile text-white'>Behance</Link>
                    <Link to='https://www.instagram.com/blankstudio.design' target='_blank' className='menu-btn-mobile text-white'>Instagram</Link>
                </div>
            </div>
        </div> */}
    </div>
  )
}

export default NavBar
