export const projectData = [
    {
        id: 1,
        title: "Tigerkhin",
        subtitle: "",
        year: "2020",
        url: "/work/tigerkhin",
        imgSrc1: "tigerkhin/cover/TIGERKHIN_C_1.jpg",
        imgSrc2: "tigerkhin/cover/TIGERKHIN_C_2.jpg",
        imgSrc3: "tigerkhin/cover/TIGERKHIN_C_3.jpg",
        imgSrc4: "tigerkhin/cover/TIGERKHIN_C_4.jpg",
        imgSrc5: "tigerkhin/cover/TIGERKHIN_C_5.jpg",
        category: "Food & Beverage",
        service: ["Brand Identity"]
    }, 
    {
        id: 2,
        title: "Acaii Tea",
        subtitle: "Bird's Nest",
        year: "2023",
        url: "/work/bird-nest",
        imgSrc1: "birdnest/cover/BIRDNEST_C_1.jpg",
        imgSrc2: "birdnest/cover/BIRDNEST_C_2.jpg",
        imgSrc3: "birdnest/cover/BIRDNEST_C_3.jpg",
        imgSrc4: "birdnest/cover/BIRDNEST_C_4.jpg",
        imgSrc5: "birdnest/cover/BIRDNEST_C_5.jpg",
        category: "Food & Beverage",
        service: ["Packaging Design"]
    },
    {
        id: 3,
        title: "Cinta Laura",
        subtitle: "Suka Kamu",
        year: "2022",
        url: "/work/suka-kamu",
        imgSrc1: "sukakamu/cover/SUKAKAMU_C_1.jpg",
        imgSrc2: "sukakamu/cover/SUKAKAMU_C_2.jpg",
        imgSrc3: "sukakamu/cover/SUKAKAMU_C_3.jpg",
        imgSrc4: "sukakamu/cover/SUKAKAMU_C_4.jpg",
        imgSrc5: "sukakamu/cover/SUKAKAMU_C_5.jpg",
        category: "Entertainment",
        service: ["Logotype Design"]
    },
    {
        id: 4,
        title: "Ramengvrl",
        subtitle: "Ain't No Motherfvckers",
        year: "2021",
        url: "/work/aint-no-mf",
        imgSrc1: "aintnomf/cover/AINTNOMF_C_1.jpg",
        imgSrc2: "aintnomf/cover/AINTNOMF_C_2.jpg",
        imgSrc3: "aintnomf/cover/AINTNOMF_C_3.jpg",
        imgSrc4: "aintnomf/cover/AINTNOMF_C_4.jpg",
        imgSrc5: "aintnomf/cover/AINTNOMF_C_5.jpg",
        category: "Entertainment",
        service: ["Logotype Design"]
    },
    {
        id: 5,
        title: "Yukishimizu Gin",
        subtitle: "",
        year: "2021",
        url: "/work/yukishimizu-gin",
        imgSrc1: "yukishimizu/cover/YUKI_C_1.jpg",
        imgSrc2: "yukishimizu/cover/YUKI_C_2.jpg",
        imgSrc3: "yukishimizu/cover/YUKI_C_3.jpg",
        imgSrc4: "yukishimizu/cover/YUKI_C_4.jpg",
        imgSrc5: "yukishimizu/cover/YUKI_C_5.jpg",
        category: "Food & Beverage",
        service: ["Packaging Design"]
    },
    {
        id: 6,
        title: "Project 1945",
        subtitle: "",
        year: "2022",
        url: "/work/poj-fou-wom",
        imgSrc1: "project1945/two/cover/PROJECT1945_C_1.jpg",
        imgSrc2: "project1945/two/cover/PROJECT1945_C_2.jpg",
        imgSrc3: "project1945/two/cover/PROJECT1945_C_3.jpg",
        imgSrc4: "project1945/two/cover/PROJECT1945_C_4.jpg",
        imgSrc5: "project1945/two/cover/PROJECT1945_C_5.jpg",
        category: "Fashion & Beauty",
        service: ["Art Direction", "Packaging Design"]
    },
    {
        id: 7,
        title: "11th Space",
        subtitle: "",
        year: "2022",
        url: "/work/11th-space",
        imgSrc1: "11thspace/cover/11THSPACE_C_1.jpg",
        imgSrc2: "11thspace/cover/11THSPACE_C_2.jpg",
        imgSrc3: "11thspace/cover/11THSPACE_C_3.jpg",
        imgSrc4: "11thspace/cover/11THSPACE_C_4.jpg",
        imgSrc5: "11thspace/cover/11THSPACE_C_5.jpg",
        category: "Services",
        service: ["Digital Design"]
    },  
    {
        id: 8,
        title: "Acaii Tea",
        subtitle: "",
        year: "2022",
        url: "/work/acaii-tea-box",
        imgSrc1: "acaiiteabox/cover/ACAIITEA_C_1.jpg",
        imgSrc2: "acaiiteabox/cover/ACAIITEA_C_2.jpg",
        imgSrc3: "acaiiteabox/cover/ACAIITEA_C_3.jpg",
        imgSrc4: "acaiiteabox/cover/ACAIITEA_C_4.jpg",
        imgSrc5: "acaiiteabox/cover/ACAIITEA_C_5.jpg",
        category: "Food & Beverage",
        service: ["Packaging Design"]
    },   
    {
        id: 9,
        title: "Bali Polo Club",
        subtitle: "",
        year: "2021",
        url: "/work/bali-polo-club",
        imgSrc1: "balipoloclub/cover/BPC_C_1.jpg",
        imgSrc2: "balipoloclub/cover/BPC_C_2.jpg",
        imgSrc3: "balipoloclub/cover/BPC_C_3.jpg",
        imgSrc4: "balipoloclub/cover/BPC_C_4.jpg",
        imgSrc5: "balipoloclub/cover/BPC_C_5.jpg",
        category: "Entertainment",
        service: ["Brand Identity"]
    },  
]