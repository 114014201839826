import React, { useEffect } from 'react'
import './footer.css'
import LogoLeft from  '../../public/asset/logo/BLANK_LOGO_LEFT.svg'
import LogoRight from  '../../public/asset/logo/BLANK_LOGO_RIGHT.svg'
import LogoVertical from '../../public/asset/logo/LOGO_BLANK STUDIO_VERTICAL.svg'
import Arrow from '../../public/asset/Icons/ARROW_BLANK_STUDIO.svg'


// import Logo from '../public/asset/navLogo.svg'

function Footer() {

    // useEffect(() => {
    //     document.querySelector('.scrollTop').addEventListener("click", function(){
    //         window.scrollTo(0, 0);
    //     })
    // }, [])

    return (
    <div className='footer-container'>
        <div className='grid-container'>
            <div className='footer-container-1'>
                <div className='footer-haas uppercase'>You can stalk us @</div>
                <div className='flex'>
                    {/* <div className='footer-haas uppercase'>Facebook</div> */}
                    <div className='footer-haas uppercase mr-[1vw]'>Instagram</div>
                    <div className='footer-haas uppercase'>Behance</div>
                </div>
            </div>
            {/* <div className='footer-container-2'>
                <div className='flex'>
                    <div className='footer-haas uppercase mr-[1vw]'>Email:</div>
                    <div className='footer-haas uppercase'>info@blankstudio.design</div>
                </div>
                <div className='flex'>
                    <div className='footer-haas uppercase mr-[1vw]'>Contact:</div>
                    <span className='footer-haas'>+62 812 8202 7430</span>
                </div>
            </div> */}
            <div className='footer-container-3'>
                <div className='flex'>
                    <div className='footer-haas uppercase mr-[1vw]'>Email:</div>
                    <div className='footer-haas uppercase'>info@blankstudio.design</div>
                </div>
                <div className='flex'>
                    <div className='footer-haas uppercase mr-[1vw]'>Contact:</div>
                    <span className='footer-haas'>+62 812 8202 7430</span>
                </div>
            </div>
            <div className='footer-container-4'>
                <div className='footer-haas uppercase'>privacy policy</div>
                <div className='flex'>
                    <div className='footer-haas uppercase'>&copy; 2023 Blank Studio</div>
                    <div className='reg-symbol-footer'>&reg;</div>
                </div>
            </div>
        </div>


        {/* <div className='footer-container'>
            <div className='line'>
                <div className='black-line'></div>
            </div> */}
            {/* <div className='grid-container'>
                <div className='section-bar'>
                    <div className='flex'>
                        <p className='section-haas'>Blank Studio&reg;</p>
                        <p className='section-haas ml-[0.4vw]'>2023</p>
                    </div>
                    <div className='flex scrollTop cursor-pointer'>
                        <img src={Arrow} alt='arrow' className='arrow-up'/>
                        <p className='section-haas'>Back to top</p>
                    </div>
                </div>
            </div>
            <div className='line'>
                <div className='black-line'></div>
            </div> */}
            {/* <div className='logo-container'>
                <div className='hidden sm:flex'>
                    <img src={LogoLeft} alt='' className='logo-left'/>
                    <img src={LogoRight} alt='' className='logo-right'/>
                </div>
                <div className='block sm:hidden'>
                    <img src={LogoVertical} alt='' className='logo-mobile'/>
                </div>
            </div> */}
            
        {/* </div> */}
    </div>
    )
}

export default Footer