import React from 'react'
import NavBar from '../../components/NavBar'
import Project1945 from '../../components/Project1945V2'

function project1945v2() {
  return (
    <>
        <NavBar />
        <Project1945 />
    </>
  )
}

export default project1945v2