import React from 'react'
import ComingSoon from '../../components/ComingSoon'

function index() {
  return (
    <>
      <ComingSoon />
    </>
  )
}

export default index