import React from 'react'
import NavBarBlack from '../../components/NavBarBlack'
import About from '../../components/About'

function index() {
  return (
    <>
      <NavBarBlack />
      <About />
    </>
  )
}

export default index