import React from 'react'
import NavBar from '../../components/NavBar'
import List from '../../components/ProjectList'
import RadioBtn from '../../components/RadioButton'


function ProjectList() {
  return (
    <>
      <NavBar />
      <RadioBtn defaultChecked={"list"}/>
      <List />
    </>
  )
}

export default ProjectList