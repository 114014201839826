// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transition-effect{
    position: absolute;
    z-index: 1000000000000000;
    background-color: black;
    top: 0;
    width: 100%;
    height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/components/Transition/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,uBAAuB;IACvB,MAAM;IACN,WAAW;IACX,aAAa;AACjB","sourcesContent":[".transition-effect{\n    position: absolute;\n    z-index: 1000000000000000;\n    background-color: black;\n    top: 0;\n    width: 100%;\n    height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
