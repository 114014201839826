import React, { useEffect } from 'react'
import "./projectexplore.css"

function ProjectExplore() {

    useEffect(() => {
        const gallery = document.getElementById("explore");

        window.addEventListener("mousemove", (event) => {
            const clientX = event.clientX;
            const clientY = event.clientY;
          
            const decimalX = clientX / window.innerWidth;
            const decimalY = clientY / window.innerHeight;
          
            const maxX = gallery.offsetWidth - window.innerWidth;
            const maxY = gallery.offsetHeight - window.innerHeight;
          
            const panX = maxX * decimalX * -1;
            const panY = maxY * decimalY * -1;
          
            gallery.animate(
              {
                transform: `translate(${panX}px, ${panY}px)`
              },
              {
                duration: 4000,
                fill: "forwards",
                easing: "ease"
              }
            );
        });

        window.addEventListener("touchmove", (event) => {
            const touch = event.touches[0];

            const clientX = touch.clientX;
            const clientY = touch.clientY;

            const decimalX = clientX / window.innerWidth;
            const decimalY = clientY / window.innerHeight;
          
            const maxX = gallery.offsetWidth - window.innerWidth;
            const maxY = gallery.offsetHeight - window.innerHeight;
          
            const panX = maxX * decimalX * -1;
            const panY = maxY * decimalY * -1;
          
            gallery.animate(
              {
                transform: `translate(${panX}px, ${panY}px)`
              },
              {
                duration: 4000,
                fill: "forwards",
                easing: "ease"
              }
            );
        });

    }, [])

    return (
    <div>
        <div className='explore-container'>
            <div id='explore'>
                <img className='explore-block' alt='' src={require(`../../public/asset/images/works/aintnomf/cover/AINTNOMF_C_4.jpg`)} />
                <img className='explore-block' alt='' src={require(`../../public/asset/images/works/tigerkhin/TIGERKHIN_7.jpg`)} />
                <img className='explore-block' alt='' src={require(`../../public/asset/images/works/sukakamu/SUKAKAMU_2.jpg`)} />
                <img className='explore-block' alt='' src={require(`../../public/asset/images/works/birdnest/BIRDNEST_FOUR.jpg`)} />
                <img className='explore-block' alt='' src={require(`../../public/asset/images/works/project1945/two/PROJECT1945_7.jpg`)} />
                <img className='explore-block' alt='' src={require(`../../public/asset/images/works/11thspace/11THSPACE_TWO.jpg`)} />
                <img className='explore-block' alt='' src={require(`../../public/asset/images/works/yukishimizu/YUKI_NINE.jpg`)} />
                <img className='explore-block' alt='' src={require(`../../public/asset/images/works/acaiiteabox/cover/ACAIITEA_C_2.jpg`)} />
                <img className='explore-block' alt='' src={require(`../../public/asset/images/works/balipoloclub/BPC_6.jpg`)} />

                {/* <img className='explore-block' src={require(`../../public/asset/images/works/sukakamu/SUKAKAMU_4.jpg`)} /> */}
                {/* <div className='explore-block'></div>
                <div className='explore-block'></div> */}
            </div>
        </div>
    </div>
  )
}

export default ProjectExplore