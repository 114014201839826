import React from 'react'
import NavBar from '../../components/NavBar'
import BaliPoloClub from '../../components/BaliPolo'

function balipolo() {
  return (
    <>
        <NavBar />
        <BaliPoloClub />
    </>
  )
}

export default balipolo