import React, { useState } from 'react'
import { ReactComponent as Logo } from '../../public/asset/logo/navLogo.svg'
import { useLocation } from 'react-router';
import { Link } from "react-router-dom"
import "./navbarblack.css"

function NavBarBlack() {
    const { pathname } = useLocation();
    const [nav, setNav] = useState(false);
    const handleNav = () => {
        setNav(!nav)
    }

    return (
        <>
            <div className='navbar-container-black'>
                <div className='grid-container'>
                    <div className='nav-logo-container'>
                        <Link to='/' className='hover-this'>
                            <Logo className='logo fill-white'/>
                        </Link>
                    </div>
                    <div className='nav-link-container'>              
                        {/* <Link to='/work'> */}
                        <Link to='/'>
                            <div className={pathname === '/work' ? 'nav-link-white link-underline-reverse-white' : 'nav-link-white link-underline-white'}>
                                Work
                            </div>
                        </Link>
                        {/* <Link to='/studio' className='mx-[1.5vw]'> */}
                        <Link to='/' className='mx-[1.5vw]'>
                            <div className={pathname === '/studio' ? 'nav-link-white link-underline-reverse-white' : 'nav-link-white link-underline-white'}>
                                Studio
                            </div>
                        </Link>
                        {/* <Link to='/contact'> */}
                        <Link to='/'>
                            <div className={pathname === '/contact' ? 'nav-link-white link-underline-reverse-white' : 'nav-link-white link-underline-white'}>
                                Contact
                            </div>
                        </Link>
                    </div>
    
                    {/* Mobile Menu Button */}
                    <div className='nav-mobile-container' onTouchStart={handleNav}>
                        <div className='menu-btn-mobile-white' id='btn-mobile'>Menu</div>
                    </div>
                </div>
            </div>
    
            {/* Mobile Menu */}
            <div className={nav ? 'nav-menu-container' : 'hidden'}>
                <div className='grid-container'>
                    <div className='nav-logo-container'>
                        <Link href='/' className='hover-this'>
                            <Logo className='logo fill-red-50'/>
                        </Link>
                    </div>
                    <div className='nav-mobile-container' onTouchStart={handleNav}>
                        <div className='menu-btn-mobile text-white' id='btn-mobile'>Close</div>
                    </div>
                </div>
                <ul className='mt-[34vh]'>
                    <li>
                        <Link to='/' className='mobile-menu'>Home</Link>
                    </li>
                    <li>
                        <Link to='/work' className='mobile-menu'>Work</Link>
                    </li>
                    <li className='py-[2vw]'>
                        <Link to='/studio' className='mobile-menu'>Studio</Link>
                    </li>
                    <li>
                        <Link to='/contact' className='mobile-menu'>Let's talk</Link>
                    </li>
                </ul>
                <div className='w-full fixed bottom-0 left-0'>
                    <div className='flex justify-between p-[5vw]'>  
                        <Link to='https://www.behance.net/blankstudio-design' target='_blank' className='menu-btn-mobile text-white'>Behance</Link>
                        <Link to='https://www.instagram.com/blankstudio.design' target='_blank' className='menu-btn-mobile text-white'>Instagram</Link>
                    </div>
                </div>
            </div>
        </>
      )
    }

export default NavBarBlack