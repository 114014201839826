import React, { useEffect } from 'react'
import "./about.css"
import { Link } from 'react-router-dom';
import { ReactComponent as LogoLeft } from  '../../public/asset/logo/BLANK_LOGO_LEFT.svg'
import { ReactComponent as LogoRight } from  '../../public/asset/logo/BLANK_LOGO_RIGHT.svg'
import { ReactComponent as LogoVertical } from '../../public/asset/logo/LOGO_BLANK STUDIO_VERTICAL.svg'
import { ReactComponent as Arrow } from '../../public/asset/Icons/ARROW_BLANK_STUDIO.svg'

import { projectData } from '../../helpers/projectdata'

function About() {

    // const sortData = [...projectData].sort((a, b) => a.title.localeCompare(b.title));
    // const [data, setData] = useState([]);

    // useEffect(() => {
    //     setData(sortData);
    // }, []);

    useEffect(() => {
        document.querySelector('.scrollTop').addEventListener("click", function(){
            window.scrollTo(0, 0);
        })
    }, []);

    // useEffect(() => {
        
    //     class LoopingElement {
    //         constructor(element, currentTranslation, speed) {
    //             this.element = element;
    //             this.currentTranslation = currentTranslation;
    //             this.speed = speed;
    //             this.direction = false;
    //             this.scrollTop = 0;
    //             this.metric = 100;
        
    //             this.lerp = {
    //                 current: this.currentTranslation,
    //                 target: this.currentTranslation,
    //                 factor: 0.2,
    //             };
        
    //             this.events();
    //             this.render();
    //         }
        
    //         events() {
    //             window.addEventListener("scroll", (e) => {
    //                 let direction =
    //                     window.pageYOffset || document.documentElement.scrollTop;
    //                 if (direction > this.scrollTop) {
    //                     // this.direction = true;
    //                     this.lerp.target -= this.speed * 5;
    //                 } else {
    //                     // this.direction = false;
    //                     this.lerp.target -= this.speed * 5;
    //                 }
    //                 this.scrollTop = direction <= 0 ? 0 : direction;
    //             });
    //         }
        
    //         lerpFunc(current, target, factor) {
    //             this.lerp.current = current * (1 - factor) + target * factor;
    //         }
        
    //         goForward() {
    //             this.lerp.target += this.speed;
    //             if (this.lerp.target > this.metric) {
    //                 this.lerp.current -= this.metric * 2;
    //                 this.lerp.target -= this.metric * 2;
    //             }
    //         }
        
    //         goBackward() {
    //             this.lerp.target -= this.speed;
    //             if (this.lerp.target < -this.metric) {
    //                 this.lerp.current -= -this.metric * 2;
    //                 this.lerp.target -= -this.metric * 2;
    //             }
    //         }
        
    //         animate() {
    //             this.direction ? this.goForward() : this.goBackward();
    //             this.lerpFunc(this.lerp.current, this.lerp.target, this.lerp.factor);
        
    //             this.element.style.transform = `translateX(${this.lerp.current}%)`;
    //         }
        
    //         render() {
    //             this.animate();
    //             window.requestAnimationFrame(() => this.render());
    //         }
    //     }

    //     class LoopingElementReverse {
    //         constructor(element, currentTranslation, speed) {
    //             this.element = element;
    //             this.currentTranslation = currentTranslation;
    //             this.speed = speed;
    //             this.direction = true;
    //             this.scrollTop = 0;
    //             this.metric = 100;
        
    //             this.lerp = {
    //                 current: this.currentTranslation,
    //                 target: this.currentTranslation,
    //                 factor: 0.2,
    //             };
        
    //             this.events();
    //             this.render();
    //         }
        
    //         events() {
    //             window.addEventListener("scroll", (e) => {
    //                 let direction =
    //                     window.pageYOffset || document.documentElement.scrollTop;
    //                 if (direction > this.scrollTop) {
    //                     // this.direction = true;
    //                     this.lerp.target += this.speed * 5;
    //                 } else {
    //                     // this.direction = false;
    //                     this.lerp.target += this.speed * 5;
    //                 }
    //                 this.scrollTop = direction <= 0 ? 0 : direction;
    //             });
    //         }
        
    //         lerpFunc(current, target, factor) {
    //             this.lerp.current = current * (1 - factor) + target * factor;
    //         }
        
    //         goForward() {
    //             this.lerp.target += this.speed;
    //             if (this.lerp.target > this.metric) {
    //                 this.lerp.current -= this.metric * 2;
    //                 this.lerp.target -= this.metric * 2;
    //             }
    //         }
        
    //         goBackward() {
    //             this.lerp.target -= this.speed;
    //             if (this.lerp.target < -this.metric) {
    //                 this.lerp.current -= -this.metric * 2;
    //                 this.lerp.target -= -this.metric * 2;
    //             }
    //         }
        
    //         animate() {
    //             this.direction ? this.goForward() : this.goBackward();
    //             this.lerpFunc(this.lerp.current, this.lerp.target, this.lerp.factor);
        
    //             this.element.style.transform = `translateX(${this.lerp.current}%)`;
    //         }
        
    //         render() {
    //             this.animate();
    //             window.requestAnimationFrame(() => this.render());
    //         }
    //     }
        
    //     let element = document.querySelectorAll(".slider-1");
    //     let element2 = document.querySelectorAll(".slider-2");
    //     let element3 = document.querySelectorAll(".slider-3");


    //     new LoopingElement(element[0], 0, 0.06);
    //     new LoopingElement(element[1], -100, 0.06);

    //     new LoopingElementReverse(element2[0], 0, 0.06);
    //     new LoopingElementReverse(element2[1], -100, 0.06);

    //     new LoopingElement(element3[0], 0, 0.06);
    //     new LoopingElement(element3[1], -100, 0.06);

    // }, []);

    
    return (
    <div>
        <div className='page-section bg-[#000000]'>
            <div className='about-container'>
                <div className='line'>
                    <div className='white-line'></div>
                </div>
                <div className='grid-container'>
                    <div className='about-bar'>
                        <p className='section-pp-black'>Tentang kami (ID)</p>
                    </div>
                    <div className='hidden sm:block about-bar'>
                        <p className='section-haas-black'>About us (EN)</p>
                    </div>
                </div>
                <div className='line'>
                    <div className='white-line'></div>
                </div>
                <div className='grid-container sm:hidden'>
                    <div className='about-block'>
                        <p className='about-pp-black'>
                            Blank Studio&reg; adalah sebuah studio kreatif multidisiplin yang berbasis di Jakarta. Tim kami 
                            dengan penuh semangat membantu organisasi mengembangkan identitas brand yang unik melalui 
                            solusi kreatif dan strategis.
                        </p>
                        <p className='about-pp-black indent-12 sm:indent-16'>
                            Kami percaya bahwa setiap brand memiliki cerita yang unik, 
                            dan kami bekerjasama dengan klien untuk menciptakan brand yang menarik, 
                            berdampak, dan berkesan.
                        </p>
                    </div>
                </div>
                <div className='block sm:hidden line'>
                    <div className='white-line'></div>
                </div>
                <div className='grid-container sm:hidden'>
                    <div className='about-bar'>
                        <p className='section-haas-black'>About us (EN)</p>
                    </div>
                </div>
                <div className='block sm:hidden line'>
                    <div className='white-line'></div>
                </div>
                <div className='grid-container'>
                    <div className='hidden sm:block about-block'>
                        <p className='about-pp-black'>
                            Blank Studio&reg; adalah sebuah studio kreatif multidisiplin yang berbasis di Jakarta. Tim kami 
                            dengan penuh semangat membantu organisasi mengembangkan identitas brand yang unik melalui 
                            solusi kreatif dan strategis.
                        </p>
                        <p className='about-pp-black indent-12 sm:indent-16'>
                            Kami percaya bahwa setiap brand memiliki cerita yang unik, 
                            dan kami bekerjasama dengan klien untuk menciptakan brand yang menarik, 
                            berdampak, dan berkesan.
                        </p>
                    </div>
                    <div className='about-block'>
                        <p className='about-haas-black'>
                            Blank Studio&reg; is a Jakarta-based multidisciplinary creative studio specializing in branding. 
                            Our team is passionate about helping organizations develop their unique brand identity through 
                            strategic and creative solutions.
                        </p>
                        <p className='about-haas-black indent-12 sm:indent-16'>
                            We believe that every brand has a unique story to tell, and 
                            we are dedicated to bring every brand vision to life. We work closely with our clients to 
                            craft brand experiences that are engaging, impactful, and memorable.
                        </p>
                    </div>
                </div>
            </div>

            {/* <div className='sticky bg-black'>
                <div className='padding-x'>
                    <div className='line'>
                        <div className='white-line'></div>
                    </div>
                    <div className='grid-container'>
                        <div className='section-bar'>
                            <p className='section-haas-black'>Clients</p>
                            <Link to='/work' className='flex'>
                                <Arrow className='arrow-right-black'/>
                                <p className='section-haas-black'>View all works</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className='line padding-x pb-[4vw] sm:pb-[3vw] lg:pb-[1.5vw]'>
                <div className='white-line'></div>
            </div> */}

            {/* <div className=' overflow-hidden'>
                <div className='slider-container'>
                    <div className='slider slider-1'>
                        <span className='about-client-pp'>, 11th Space</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/11thspace/11THSPACE_ELEVEN.jpg`)} />
                        <span className='about-client-pp'>, Acaii Tea</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/birdnest/BIRDNEST_ONE.jpg`)} />
                        <span className='about-client-pp'>, Ramengvrl</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/aintnomf/AINTNOMF_2.jpg`)} />
                    </div>
                    <div className='slider slider-1'>
                        <span className='about-client-pp'>, 11th Space</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/11thspace/11THSPACE_ELEVEN.jpg`)} />
                        <span className='about-client-pp'>, Acaii Tea</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/birdnest/BIRDNEST_ONE.jpg`)} />
                        <span className='about-client-pp'>, Ramengvrl</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/aintnomf/AINTNOMF_2.jpg`)} />
                    </div>
                </div>
                <div className='slider-container'>
                    <div className='slider slider-2'>
                        <span className='about-client-pp'>, Cinta Laura</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/sukakamu/SUKAKAMU_5.jpg`)} />
                        <span className='about-client-pp'>, Tigerkhin</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/tigerkhin/TIGERKHIN_4.jpg`)} />
                        <span className='about-client-pp'>, Project 1945</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/project1945/two/PROJECT1945_7.jpg`)} />
                    </div>
                    <div className='slider slider-2'>
                        <span className='about-client-pp'>, Cinta Laura</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/sukakamu/SUKAKAMU_5.jpg`)} />
                        <span className='about-client-pp'>, Tigerkhin</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/tigerkhin/TIGERKHIN_4.jpg`)} />
                        <span className='about-client-pp'>, Project 1945</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/project1945/two/PROJECT1945_7.jpg`)} />
                    </div>
                </div>
                <div className='slider-container'>
                    <div className='slider slider-3'>
                        <span className='about-client-pp'>, Yukishimizu</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/yukishimizu/YUKI_NINE.jpg`)} />
                        <span className='about-client-pp'>, Acaii Tea</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/birdnest/BIRDNEST_ONE.jpg`)} />
                        <span className='about-client-pp'>, Bali Polo Club</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/balipoloclub/BPC_6.jpg`)} />
                    </div>
                    <div className='slider slider-3'>
                        <span className='about-client-pp'>, Yukishimizu</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/yukishimizu/YUKI_NINE.jpg`)} />
                        <span className='about-client-pp'>, Acaii Tea</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/birdnest/BIRDNEST_ONE.jpg`)} />
                        <span className='about-client-pp'>, Bali Polo Club</span>
                        <img alt='' className='about-client-img' src={require(`../../public/asset/images/works/balipoloclub/BPC_6.jpg`)} />
                    </div>
                </div>
            </div> */}

            {/* <div className='sticky-second bg-black'>
                <div className='padding-x'>
                    <div className='line'>
                        <div className='white-line'></div>
                    </div>
                    <div className='grid-container'>
                        <div className='section-bar'>
                            <p className='section-haas-black'>Services</p>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className='line padding-x'>
                <div className='white-line'></div>
            </div> */}
                    
            <div className='about-container'>
                {/* <div className='grid-container about-section'>
                    <div className='col-start-1 col-end-3 sm:col-start-4 sm:col-end-6 lg:col-start-8 lg:col-end-9'>
                        <p className='about-haas-black'>Branding</p>
                    </div>
                    <div className='col-start-3 col-end-5 sm:col-start-6 sm:col-end-9 lg:col-start-10 lg:col-end-13'>
                        <p className='about-pp-black'>Brand and Visual Identity</p>
                        <p className='about-pp-black'>Brand Strategy</p>
                        <p className='about-pp-black'>Logo System</p>
                        <p className='about-pp-black'>Art and Creative Direction</p>
                        <p className='about-pp-black'>Name Development</p>
                        <p className='about-pp-black'>Brand Applications</p>
                        <p className='about-pp-black'>Typography</p>
                    </div>
                </div> */}
                {/* <div className='grid-container about-section'>
                    <div className='col-start-1 col-end-3 sm:col-start-4 sm:col-end-6 lg:col-start-8 lg:col-end-9'>
                        <p className='about-haas-black'>Print</p>
                    </div>
                    <div className='col-start-3 col-end-5 sm:col-start-6 sm:col-end-9 lg:col-start-10 lg:col-end-13'>
                        <p className='about-pp-black'>Packaging Design</p>
                        <p className='about-pp-black'>Label Design</p>
                        <p className='about-pp-black'>Books & Editorial</p>
                        <p className='about-pp-black'>Company Profile</p>
                        <p className='about-pp-black'>Marketing Collaterals</p>
                    </div>
                </div> */}
                {/* <div className='grid-container about-section'>
                    <div className='col-start-1 col-end-3 sm:col-start-4 sm:col-end-6 lg:col-start-8 lg:col-end-9'>
                        <p className='about-haas-black'>Digital</p>
                    </div>
                    <div className='col-start-3 col-end-5 sm:col-start-6 sm:col-end-9 lg:col-start-10 lg:col-end-13'>
                        <p className='about-pp-black link-underline'>Responsive Web Design</p>
                        <p className='about-pp-black'>Web Development</p>
                        <p className='about-pp-black'>Digital Strategy</p>
                        <p className='about-pp-black'>Social Media Design / Guidelines</p>
                        <p className='about-pp-black'>Motion</p>
                    </div>
                </div> */}
                {/* <div className='line'>
                    <div className='white-line'></div>
                </div>
                <div className='grid-container'>
                    <div className='about-bar'>
                        <p className='section-haas-black'>Contact</p>
                    </div>
                </div> */}
                <div className='line'>
                    <div className='white-line'></div>
                </div>

                <div className='grid-container py-[4vw] sm:py-[2vw] lg:py-[1vw]'>
                    <div className='col-start-1 col-end-5 sm:col-end-9 lg:col-end-13 flex justify-between'>
                        <Link to='mailto:info@blankstudio.design'>
                            <div className='block sm:flex link-underline-black sm:h-[6vw]'>
                                <span className='contact-haas'>info</span>
                                <span className='contact-pp'>@</span>
                                <span className='contact-haas'>blankstudio.design</span>
                                <span className='contact-haas'></span>
                            </div>
                        </Link>
                        <Link to='https://www.behance.net/blankstudio-design' target='_blank'>
                            <p className='contact-haas link-underline-black'>Behance</p>
                        </Link>
                    </div>
                    <div className='col-start-1 col-end-5 sm:col-end-9 lg:col-end-13 flex justify-between'>
                        <Link to='https://www.instagram.com/blankstudio.design' target='_blank'>
                            <p className='contact-haas link-underline-black'>Instagram</p>
                        </Link>
                        <Link to='https://wa.me/6281282027430'>
                            <div className='flex'>
                                <span className='contact-pp pr-[1vw]'>(+62)</span>
                                <span className='contact-haas'>812 8202 7430</span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className='about-footer'>
                    <div className='line'>
                        <div className='white-line'></div>
                    </div>
                    <div className='grid-container'>
                        <div className='section-bar'>
                            <div className='flex'>
                                <p className='section-haas-black'>Blank Studio&reg;</p>
                                <p className='section-haas-black ml-[0.4vw]'>2023</p>
                            </div>
                            <div className='flex scrollTop cursor-pointer'>
                                <Arrow className='arrow-up-black'/>
                                <p className='section-haas-black'>Back to top</p>
                            </div>
                        </div>
                    </div>
                    <div className='line'>
                        <div className='white-line'></div>
                    </div>
                    <div className='logo-container'>
                        <div className='hidden sm:flex'>
                            <LogoLeft alt='' className='logo-left fill-[#ffffff]'/>
                            <LogoRight alt='' className='logo-right fill-[#ffffff]'/>
                        </div>
                        <div className='block sm:hidden'>
                            <LogoVertical alt='' className='logo-mobile fill-[#ffffff]'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default About