import { useEffect, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;

    // useLayoutEffect(() => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: 'instant',
    //     });
    // }, [pathname]);

    // return null;
};

export default ScrollToTop