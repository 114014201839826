import React from 'react'
import NavBar from '../../components/NavBar'
import SukaKamu from '../../components/SukaKamu'

function sukakamu() {
  return (
    <>
        <NavBar />
        <SukaKamu />
    </>
  )
}

export default sukakamu