import React from 'react'
import NavBar from '../../components/NavBar'
import Tigerkhin from '../../components/Tigerkhin'

function tigerkhin() {
  return (
    <>
        <NavBar />
        <Tigerkhin />
    </>  
    )
}

export default tigerkhin