import { BrowserRouter, Routes, Route } from "react-router-dom";
import Soon from './modules/comingsoon/index'
import Home from "./modules/home/index";
import Project from "./modules/project/index"
import Explore from "./modules/explore/index"
import List from './modules/list/index'
import Studio from "./modules/about/index"
import Aintnomotherfvckers from "./modules/work/aint-no-motherfvckers";
import Birdnest from "./modules/work/bird-nest";
import Tigerkhin from "./modules/work/tigerkhin";
import SukaKamu from "./modules/work/sukakamu";
import Yukishimizu from "./modules/work/yukishimizu";
import Project1945v2 from './modules/work/project1945v2'
import EleventhSpace from './modules/work/11thspace'
import AcaiiTeaBox from './modules/work/acaiiteabox'
import BaliPolo from "./modules/work/balipolo";

import ScrollToTop from './helpers/ScrollToTop'
import NavBar from "./components/NavBar";

function App() {
  return (
    <BrowserRouter>
      {/* <NavBar /> */}
      <ScrollToTop />
      <Routes>
        {/* <Route index element={<Soon />} /> */}
        <Route index element={<Home />} />
        {/* <Route path="/home" element={<Home />} /> */}
        <Route path="/studio" element={<Studio />} />
        <Route path="/work" element={<Project />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/work-list" element={<List />} />
        <Route path="/work/aint-no-mf" element={<Aintnomotherfvckers />} />
        <Route path="/work/bird-nest" element={<Birdnest />} />
        <Route path="/work/tigerkhin" element={<Tigerkhin />} />
        <Route path="/work/suka-kamu" element={<SukaKamu />} />
        <Route path="/work/yukishimizu-gin" element={<Yukishimizu />} />
        <Route path="/work/poj-fou-wom" element={<Project1945v2 />} />
        <Route path="/work/11th-space" element={<EleventhSpace />} />
        <Route path="/work/acaii-tea-box" element={<AcaiiTeaBox />} />
        <Route path="/work/bali-polo-club" element={<BaliPolo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
