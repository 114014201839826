import React from 'react'
import NavBar from '../../components/NavBar'
import Explore from '../../components/ProjectExplore'
import RadioBtn from '../../components/RadioButton'


function ProjectExplore() {
  return (
    <>
      <NavBar />
      <RadioBtn defaultChecked={"explore"}/>
      <Explore />
    </>
  )
}

export default ProjectExplore