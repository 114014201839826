import React, {useRef, useEffect, useState} from 'react'
import './styles.css'
import { Power1 } from 'gsap'
// import CircularWithValueLabel from './count.tsx';

function Transition(props) {
    const { timeline, resume } = props;
    const trans = useRef(null);
    useEffect(() => {
        timeline.to(trans.current, {
            delay: 1,
            duration: 4,
            x: 2500,
            ease: Power1.easeOut
        });
    })
    useEffect(()=>{
        console.log('Transition', resume)
        resume && timeline.resume();
    }, [resume])
    console.log('timeline', timeline)
    timeline.pause()
    return (

        <div>
           <div className="transition-effect" ref={trans}>
            {/* <CircularWithValueLabel /> pakai MUI*/}
            </div> 
        </div>
    )
}

export default Transition