import React from 'react'
import "./hero2.css"

import Round from '../../public/asset/images/hero/HP_ROUND.gif'
import Img1 from '../../public/asset/images/hero/HP_1.gif'
import Img2 from '../../public/asset/images/hero/HP_2.gif'
import Img3 from '../../public/asset/images/hero/HP_3.gif'
import Img4 from '../../public/asset/images/hero/HP_4.jpg'
import Img5 from '../../public/asset/images/hero/HP_5.png'

import Arrow from '../../public/asset/Icons/ARROW_BLANK_STUDIO.svg'

function Hero2() {
  return (
    <div>
        {/* <div className='w-full h-screen'>

        </div> */}
        <div className='hero2-section'>
            <div className='hero2-container'>
                <div className='web2-container '>
                    <div className='flex'>
                        <span className='hero2-text-pp tracking-[-0.5vw]'>Blank Studio</span> 
                        <span className='hero2-reg-pp tracking-[-0.5vw] mr-[1vw]'>&reg;</span> 
                        <span className='hero2-text-haas'>is a multidisciplinary</span>
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'> creative studi</span>
                        <img src={Round} alt='' className='round-gif' /> 
                        <span className='hero2-text-haas px-[1vw]'>based in</span>  
                        <span className='hero2-text-pp tracking-[-0.5vw]'>Jakarta</span>  
                        <span className='hero-line-container'>
                            <div className='hero-line'></div>
                        </span>
                        <img src={Img5} alt='' className='hero-elipse'/> 
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>operating across Indonesia &</span>
                        <img src={Img1} alt='' className='hero-gif mx-[1vw]'/> 

                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>globally. Blending</span>  
                        <span className='hero2-text-pp tracking-[-0.5vw] mx-[1vw]'>creativity</span>
                        <span className='hero2-text-haas'>and</span>  
                        <img src={Img2} alt='' className='hero-gif mx-[1vw]'/> 
                    </div>
                    <div className='flex'>
                        <img src={Img3} alt='' className='hero-gif mr-[0.5vw]'/> 
                        <img src={Img4} alt='' className='hero-gif mr-[1vw]'/> 
                        <span className='hero2-text-pp tracking-[-0.5vw] pr-[2vw]'>strategic thinking</span>
                        <span className='hero2-text-haas pr-[1vw]'>to help brands</span>     
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>make a meaningful difference.</span> 
                    </div>
                </div>

                {/* Mobile */}
                <div className='mobile2-container '>
                    <div className='flex'>
                        <span className='hero2-text-pp tracking-[-0.5vw]'>Blank Studio</span> 
                        <span className='hero2-reg-pp tracking-[-0.5vw] mr-[2vw]'>&reg;</span> 
                        <span className='hero2-text-haas'>is a</span>
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas mr-[1vw]'>multidisciplinary</span>
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>creative studi</span>
                        <img src={Round} alt='' className='round-gif' />
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>based in</span>
                        <span className='hero2-text-pp tracking-[-0.5vw] ml-[2vw]'>Jakarta</span>
                        <img src={Img5} alt='' className='hero-elipse'/> 
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>operating across</span> 
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>Indonesia &</span>
                        <img src={Img1} alt='' className='hero-gif ml-[2vw]'/>  
                        {/* <span className='hero2-text-haas'>&</span> */}
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>globally. Blending</span>
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-pp tracking-[-0.5vw]'>creativity</span>
                        <img src={Img2} alt='' className='hero-gif mx-[2vw]'/>  
                        <span className='hero2-text-haas '>and</span>
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-pp tracking-[-0.5vw]'>strategic thinking</span>
                    </div>
                    <div className='flex'>
                        <img src={Img3} alt='' className='hero-gif mr-[1vw]'/> 
                        <img src={Img4} alt='' className='hero-gif mr-[2vw]'/> 
                        <span className='hero2-text-haas'>to help brands</span> 
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>make a meaning-</span>     
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>ful difference.</span>     
                    </div>


                    {/* <div className='flex'>
                        <img src={Img1} alt='' className='hero-gif mr-[2vw]'/>  
                        <span className='hero2-text-haas'>Blending</span>
                        <span className='hero2-text-pp tracking-[-0.5vw] mx-[2vw]'>creativity</span>
                    </div>


                    <div className='flex'>
                        <span className='hero2-text-haas'>operating across</span> 
                        <img src={Img1} alt='' className='hero-gif ml-[2vw]'/>  
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>Indonesia and globally.</span>
                    </div> */}
                    {/* <div className='flex'>
                        <span className='hero2-text-haas'>Blending</span>
                        <span className='hero2-text-pp tracking-[-0.5vw] mx-[2vw]'>creativity</span>
                        <img src={Img2} alt='' className='hero-gif '/> 
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas mr-[2vw]'>&</span>     
                        <span className='flex mr-[2vw]'>
                            <img src={Img3} alt='' className='hero-gif mr-[1vw]'/> 
                            <img src={Img4} alt='' className='hero-gif'/> 
                        </span>
                        <span className='hero2-text-pp tracking-[-0.5vw]'>strategic thinking</span>
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>to help brands make a</span>     
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>meaningful difference.</span>     
                    </div> */}
                    {/* <div className='flex'>
                        <span className='hero2-text-haas'>based in Jakarta,</span>
                        <span className='hero-line-container'>
                            <div className='hero-line'></div>
                        </span>
                        <img src={Img5} alt='' className='hero-elipse'/> 
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>operating across</span> 
                        <img src={Img1} alt='' className='hero-gif ml-[2vw]'/>  
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>Indonesia and globally.</span>
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>Blending</span>
                        <img src={Img2} alt='' className='hero-gif mx-[2vw]'/> 
                        <span className='hero2-text-pp tracking-[-0.5vw]'>creativity</span>
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas mr-[1vw]'>and</span>     
                        <span className='flex mr-[2vw]'>
                            <img src={Img3} alt='' className='hero-gif mr-[1vw]'/> 
                            <img src={Img4} alt='' className='hero-gif'/> 
                        </span>
                        <span className='hero2-text-pp tracking-[-0.5vw]'>strategic thinking</span>
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>to help brands make a</span>     
                    </div>
                    <div className='flex'>
                        <span className='hero2-text-haas'>meaningful difference.</span>     
                    </div> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Hero2