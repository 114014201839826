import React from 'react'
import NavBar from '../../components/NavBar'
import Acaii from '../../components/AcaiiTeaBox'

function acaiiteabox() {
  return (
    <>
        <NavBar />
        <Acaii />
    </>
  )
}

export default acaiiteabox